import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import {
  InvestContainer,
  InvestWrapper,
  InvestH1,
  InvestH4,
} from './GraphicElements';

const GrafikInvestasi = ({ data }) => {
  const filteredData = data.filter(item => item.tahun);

  const chartData = {
    labels: filteredData.map((item) => item.tahun),
    datasets: [
      {
        label: 'Akumulasi Iuran',
        data: filteredData.map((item) => item.akumulasiIuran),
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
        fill: true,
      },
      {
        label: 'Akumulasi Pengembangan',
        data: filteredData.map(
          (item) => item.akumulasiSetelahBiaya - item.akumulasiIuran
        ),
        borderColor: 'rgba(153,102,255,1)',
        backgroundColor: 'rgba(153,102,255,0.2)',
        fill: true,
      },
      {
        label: 'Saldo (Setelah Dipotong Biaya)',
        data: filteredData.map((item) => item.akumulasiSetelahBiaya),
        borderColor: 'rgba(255,159,64,1)',
        backgroundColor: 'rgba(255,159,64,0.2)',
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false, // Menampilkan semua data di tooltip
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            // Menambahkan teks "Usia ke-..." pada judul
            const tahun = tooltipItems[0].label;
            return `Tahun ke-${tahun}`;
          },
          label: function (tooltipItem) {
            // Format angka uang menjadi Rp dengan dua desimal
            const value = tooltipItem.raw.toLocaleString('id-ID', {
              style: 'currency',
              currency: 'IDR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return `${tooltipItem.dataset.label}: ${value}`;
          },
        },
      },
      crosshairLines: {
        verticalLineColor: 'rgba(0,0,0,0.6)', // Warna garis vertikal
        horizontalLineColor: 'rgba(0,0,0,0.6)', // Warna garis horizontal
        lineWidth: 1.5, // Ketebalan garis
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Tahun',
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Jumlah (dalam Rp)',
        },
      },
    },
  };

  // Plugin kustom untuk garis vertikal dan horizontal
  const crosshairLinesPlugin = {
    id: 'crosshairLines',
    beforeDraw: (chart) => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx = chart.ctx;
        const activePoint = chart.tooltip._active[0];

        const x = activePoint.element.x; // Posisi x (garis vertikal)
        const y = activePoint.element.y; // Posisi y (garis horizontal)

        const topY = chart.scales.y.top; // Batas atas grafik
        const bottomY = chart.scales.y.bottom; // Batas bawah grafik
        const leftX = chart.scales.x.left; // Batas kiri grafik
        const rightX = chart.scales.x.right; // Batas kanan grafik

        ctx.save();

        // Mengatur garis menjadi putus-putus
        ctx.setLineDash([5, 5]); // Format garis putus-putus: 5px garis, 5px jarak

        // Menggambar garis vertikal
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = options.plugins.crosshairLines.lineWidth;
        ctx.strokeStyle = options.plugins.crosshairLines.verticalLineColor;
        ctx.stroke();
        ctx.closePath();

        // Menggambar garis horizontal
        ctx.beginPath();
        ctx.moveTo(leftX, y);
        ctx.lineTo(rightX, y);
        ctx.lineWidth = options.plugins.crosshairLines.lineWidth;
        ctx.strokeStyle = options.plugins.crosshairLines.horizontalLineColor;
        ctx.stroke();
        ctx.closePath();

        // Menghapus efek garis putus-putus setelah selesai
        ctx.setLineDash([]);

        ctx.restore();
      }
    },
  };

  return (
    <InvestContainer id="graphic">
      <InvestH1><b>Grafik Hasil Simulasi</b></InvestH1>
      <InvestWrapper>
        <Line
          data={chartData}
          options={options}
          plugins={[crosshairLinesPlugin]}
        />
      </InvestWrapper>
    </InvestContainer>
  );
};

export default GrafikInvestasi;
