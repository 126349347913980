import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';
import {
  Container,
  FormWrap,
  FormContent,
  Form,
  FormInput,
  FormLabel,
  FormButton,
  FormSelect,
  FormOption,
  FormCardWrapper,
  FormDiv,
  FormH3,
  FormH2,
  FormH2Error,
  FormH1,
  FormInputWrapper,
  FormInputDiv,
  FormText,
  FormRupiahLeft,
  FormAgreeLeft,
  FormH1Error,
} from './PengkinianElements';
import {
  ErrorCard,
  MessageH1,
  MessageH2,
} from './MessageElements';
import SuccessModal from '../Modal/Sukses';
import { animateScroll as scroll } from 'react-scroll';

const Pengkinian = ({ userData }) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  const [data, setData] = useState({
    nama: '',
    jenis_kelamin: '',
    tempat_lahir: '',
    tanggal_lahir: '',
    no_identitas: '',
    npwp: '',
    email: '',
    alamat: '',
    rtrw: '',
    kelurahan: '',
    kecamatan: '',
    kota: '',
    provinsi: '',
    kodepos: '',
    alamat_rumah: '',
    rtrw_rumah: '',
    kelurahan_rumah: '',
    kecamatan_rumah: '',
    kota_rumah: '',
    provinsi_rumah: '',
    kodepos_rumah: '',
    no_hp: '',
    no_telp: '',
    ibu_kandung: '',
    perusahaan: '',
    pekerjaan: '',
    pemilikan: '',
    bidang_pekerjaan: '',
    alamat_kantor: '',
    kelurahan_kantor: '',
    kecamatan_kantor: '',
    kota_kantor: '',
    provinsi_kantor: '',
    kodepos_kantor: '',
    penghasilan_tetap: '',
    penghasilan_tidak_tetap: '',
    tanggal_registrasi: '',
    usia_pensiun: '',
    foto_ktp: null,
    foto_npwp: null,
    samaDenganKTP: false
  });

  const history = useHistory();
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [pekerjaanData, setPekerjaan] = useState([]);
  const [pemilikanData, setPemilikan] = useState([]);
  const [usahaData, setUsaha] = useState([]);
  const [penghasilanData, setPenghasilan] = useState([]);
  const [penghasilanTidakTetapData, setPenghasilanTidakTetap] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setKota] = useState([]);
  const [subdistricts, setKecamatan] = useState([]);
  const [villages, setKelurahan] = useState([]);
  const [districtsRumah, setKotaRumah] = useState([]);
  const [subdistrictsRumah, setKecamatanRumah] = useState([]);
  const [villagesRumah, setKelurahanRumah] = useState([]);
  const [districtsKantor, setKotaKantor] = useState([]);
  const [subdistrictsKantor, setKecamatanKantor] = useState([]);
  const [villagesKantor, setKelurahanKantor] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});

  const fieldRefs = {
    nama: useRef(null),
    jenis_kelamin: useRef(null),
    tempat_lahir: useRef(null),
    tanggal_lahir: useRef(null),
    no_identitas: useRef(null),
    npwp: useRef(null),
    email: useRef(null),
    alamat: useRef(null),
    rtrw: useRef(null),
    kelurahan: useRef(null),
    kecamatan: useRef(null),
    kota: useRef(null),
    provinsi: useRef(null),
    kodepos: useRef(null),
    alamat_rumah: useRef(null),
    rtrw_rumah: useRef(null),
    kelurahan_rumah: useRef(null),
    kecamatan_rumah: useRef(null),
    kota_rumah: useRef(null),
    provinsi_rumah: useRef(null),
    kodepos_rumah: useRef(null),
    no_hp: useRef(null),
    ibu_kandung: useRef(null),
    perusahaan: useRef(null),
    pekerjaan: useRef(null),
    pemilikan: useRef(null),
    bidang_pekerjaan: useRef(null),
    alamat_kantor: useRef(null),
    kelurahan_kantor: useRef(null),
    kecamatan_kantor: useRef(null),
    kota_kantor: useRef(null),
    provinsi_kantor: useRef(null),
    kodepos_kantor: useRef(null),
    penghasilan_tetap: useRef(null),
    penghasilan_tidak_tetap: useRef(null),
    foto_ktp: useRef(null),
  };

  useEffect(() => {
    fetchData();
  }, [userData.username]);

  const fetchData = () => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + `/ppip/peserta/${userData.username}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const fetchedData = response.data.content[0];
        setData({
          ...data,
          nama: fetchedData.nama_lengkap,
          tanggal_registrasi: fetchedData.tgl_registrasi,
          usia_pensiun: fetchedData.usia_pensiun
        });
      })
      .catch((error) => {
        console.error(error.response.data.message);
      });
  };

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_BASE_URL + `/master/pekerjaan`)
      .then(response => {
        setPekerjaan(response.data.content);
      })
      .catch(error => console.error(error.response.data.message));
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_BASE_URL + `/master/kepemilikan`)
      .then(response => {
        setPemilikan(response.data.content);
      })
      .catch(error => console.error(error.response.data.message));
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_BASE_URL + `/master/usaha`)
      .then(response => {
        setUsaha(response.data.content);
      })
      .catch(error => console.error(error.response.data.message));
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_BASE_URL + `/master/penghasilan`)
      .then(response => {
        setPenghasilan(response.data.content);
        setPenghasilanTidakTetap(response.data.content);
      })
      .catch(error => console.error(error.response.data.message));
  }, []);

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/wilayah/province`)
      .then(response => {
        setProvinces(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  }, []);

  const handleProvinsiChange = (event) => {
    const selectedProvinsi = provinces.find(provinsi => provinsi.name === event.target.value);
    if (!selectedProvinsi) {
      console.error('Provinsi not found:', event.target.value);
      return;
    }
    setData(prevState => ({
      ...prevState,
      provinsi: selectedProvinsi.name,
    }));

    axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/wilayah/district?provinsi_id=${selectedProvinsi.id}`)
      .then(response => {
        setKota(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  };

  const handleKotaChange = (event) => {
    const selectedKota = districts.find(kota => kota.name === event.target.value);
    if (!selectedKota) {
      console.error('Kota not found:', event.target.value);
      return;
    }
    setData(prevState => ({
      ...prevState,
      kota: selectedKota.name,
    }));

    axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/wilayah/subdistrict?kabupaten_id=${selectedKota.id}`)
      .then(response => {
        setKecamatan(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  };

  const handleKecamatanChange = (event) => {
    const selectedKecamatan = subdistricts.find(kecamatan => kecamatan.name === event.target.value);
    if (!selectedKecamatan) {
      console.error('Kecamatan not found:', event.target.value);
      return;
    }
    setData(prevState => ({
      ...prevState,
      kecamatan: selectedKecamatan.name,
    }));

    axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/wilayah/village?kecamatan_id=${selectedKecamatan.id}`)
      .then(response => {
        setKelurahan(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  };

  const handleKelurahanChange = (event) => {
    setData(prevState => ({
      ...prevState,
      kelurahan: event.target.value,
    }));
  };

  const handleProvinsiRumahChange = (event) => {
    const selectedProvinsiRumah = provinces.find(provinsi => provinsi.name === event.target.value);
    if (!selectedProvinsiRumah) {
      console.error('Provinsi not found:', event.target.value);
      return;
    }
    setData(prevState => ({
      ...prevState,
      provinsi_rumah: selectedProvinsiRumah.name,
    }));

    axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/wilayah/district?provinsi_id=${selectedProvinsiRumah.id}`)
      .then(response => {
        setKotaRumah(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  };

  const handleKotaRumahChange = (event) => {
    const selectedKotaRumah = districtsRumah.find(kota => kota.name === event.target.value);
    if (!selectedKotaRumah) {
      console.error('Kota not found:', event.target.value);
      return;
    }
    setData(prevState => ({
      ...prevState,
      kota_rumah: selectedKotaRumah.name,
    }));

    axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/wilayah/subdistrict?kabupaten_id=${selectedKotaRumah.id}`)
      .then(response => {
        setKecamatanRumah(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  };

  const handleKecamatanRumahChange = (event) => {
    const selectedKecamatanRumah = subdistrictsRumah.find(kecamatan => kecamatan.name === event.target.value);
    if (!selectedKecamatanRumah) {
      console.error('Kecamatan not found:', event.target.value);
      return;
    }
    setData(prevState => ({
      ...prevState,
      kecamatan_rumah: selectedKecamatanRumah.name,
    }));

    axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/wilayah/village?kecamatan_id=${selectedKecamatanRumah.id}`)
      .then(response => {
        setKelurahanRumah(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  };

  const handleKelurahanRumahChange = (event) => {
    setData(prevState => ({
      ...prevState,
      kelurahan_rumah: event.target.value,
    }));
  };

  const handleProvinsiKantorChange = (event) => {
    const selectedProvinsiKantor = provinces.find(provinsi => provinsi.name === event.target.value);
    if (!selectedProvinsiKantor) {
      console.error('Provinsi not found:', event.target.value);
      return;
    }
    setData(prevState => ({
      ...prevState,
      provinsi_kantor: selectedProvinsiKantor.name,
    }));

    axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/wilayah/district?provinsi_id=${selectedProvinsiKantor.id}`)
      .then(response => {
        setKotaKantor(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  };

  const handleKotaKantorChange = (event) => {
    const selectedKotaKantor = districtsKantor.find(kota => kota.name === event.target.value);
    if (!selectedKotaKantor) {
      console.error('Kota not found:', event.target.value);
      return;
    }
    setData(prevState => ({
      ...prevState,
      kota_kantor: selectedKotaKantor.name,
    }));

    axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/wilayah/subdistrict?kabupaten_id=${selectedKotaKantor.id}`)
      .then(response => {
        setKecamatanKantor(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  };

  const handleKecamatanKantorChange = (event) => {
    const selectedKecamatanKantor = subdistrictsKantor.find(kecamatan => kecamatan.name === event.target.value);
    if (!selectedKecamatanKantor) {
      console.error('Kecamatan not found:', event.target.value);
      return;
    }
    setData(prevState => ({
      ...prevState,
      kecamatan_kantor: selectedKecamatanKantor.name,
    }));

    axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/wilayah/village?kecamatan_id=${selectedKecamatanKantor.id}`)
      .then(response => {
        setKelurahanKantor(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  };

  const handleKelurahanKantorChange = (event) => {
    setData(prevState => ({
      ...prevState,
      kelurahan_kantor: event.target.value,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setData((prevData) => ({
        ...prevData,
        samaDenganKTP: checked,
        alamat_rumah: checked ? prevData.alamat : '',
        rtrw_rumah: checked ? prevData.rtrw : '',
        kodepos_rumah: checked ? prevData.kodepos : '',
        provinsi_rumah: checked ? prevData.provinsi : '',
        kota_rumah: checked ? prevData.kota : '',
        kecamatan_rumah: checked ? prevData.kecamatan : '',
        kelurahan_rumah: checked ? prevData.kelurahan : '',
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
        ...(prevData.samaDenganKTP && {
          alamat_rumah: name === 'alamat' ? value : prevData.alamat_rumah,
          rtrw_rumah: name === 'rtrw' ? value : prevData.rtrw_rumah,
          kodepos_rumah: name === 'kodepos' ? value : prevData.kodepos_rumah,
          provinsi_rumah: name === 'provinsi' ? value : prevData.provinsi_rumah,
          kota_rumah: name === 'kota' ? value : prevData.kota_rumah,
          kecamatan_rumah: name === 'kecamatan' ? value : prevData.kecamatan_rumah,
          kelurahan_rumah: name === 'kelurahan' ? value : prevData.kelurahan_rumah,
        }),
      }));
    }
    setErrors({ ...errors, [name]: '' });
  };

  const handleFileChangeKTP = (e) => {
    const { name, files } = e.target;
    const selectedFile = files[0];
    const maxSize = 5 * 1024 * 1024; // 5 MB

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (selectedFile) {
        if (selectedFile.size > maxSize) {
          updatedErrors.dokumen_ktp = 'Ukuran file tidak boleh melebihi 5 MB.';
        } else {
          delete updatedErrors.dokumen_ktp;
        }
      }

      return updatedErrors;
    });

    if (selectedFile && selectedFile.size <= maxSize) {
      setData((prevData) => ({
        ...prevData,
        [name]: selectedFile,
      }));
    }
  };

  const handleFileChangeNPWP = (e) => {
    const { name, files } = e.target;
    const selectedFile = files[0];
    const maxSize = 5 * 1024 * 1024; // 5 MB

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (selectedFile) {
        if (selectedFile.size > maxSize) {
          updatedErrors.dokumen_npwp = 'Ukuran file tidak boleh melebihi 5 MB.';
        } else {
          delete updatedErrors.dokumen_npwp;
        }
      }

      return updatedErrors;
    });

    if (selectedFile && selectedFile.size <= maxSize) {
      setData((prevData) => ({
        ...prevData,
        [name]: selectedFile,
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setIsCheckboxChecked(checked);
    setShowButton(checked);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!data.nama) {
      newErrors.nama = 'Nama harus diisi.';
    }
    if (!data.tanggal_lahir || data.tanggal_lahir === '0000-00-00') {
      newErrors.tanggal_lahir = 'Tanggal lahir harus diisi.';
    }
    if (!data.tempat_lahir) {
      newErrors.tempat_lahir = 'Tempat lahir harus diisi.';
    }
    if (!data.no_identitas) {
      newErrors.no_identitas = 'Nomor Kartu Identitas harus diisi.';
    }
    if (!data.npwp) {
      newErrors.npwp = 'NPWP harus diisi.';
    }
    if (!data.jenis_kelamin) {
      newErrors.jenis_kelamin = 'Jenis kelamin harus diisi.';
    }
    if (!data.ibu_kandung) {
      newErrors.ibu_kandung = 'Nama ibu kandung harus diisi.';
    }
    if (!data.no_hp) {
      newErrors.no_hp = 'Nomor Telepon Genggam harus diisi.';
    }
    if (!data.no_telp) {
      newErrors.no_telp = 'Nomor Telepon Rumah harus diisi.';
    }
    if (!data.alamat) {
      newErrors.alamat = 'Alamat harus diisi.';
    }
    if (!data.alamat_rumah) {
      newErrors.alamat_rumah = 'Alamat rumah harus diisi.';
    }
    if (!data.alamat_kantor) {
      newErrors.alamat_kantor = 'Alamat kantor harus diisi.';
    }
    if (!data.rtrw) {
      newErrors.rtrw = 'RT/RW harus diisi.';
    }
    if (!data.rtrw_rumah) {
      newErrors.rtrw_rumah = 'RT/RW rumah harus diisi.';
    }
    if (!data.kelurahan) {
      newErrors.kelurahan = 'Desa/Kelurahan harus diisi.';
    }
    if (!data.kelurahan_rumah) {
      newErrors.kelurahan_rumah = 'Desa/Kelurahan rumah harus diisi.';
    }
    if (!data.kelurahan_kantor) {
      newErrors.kelurahan_kantor = 'Desa/Kelurahan kantor harus diisi.';
    }
    if (!data.kecamatan) {
      newErrors.kecamatan = 'Kecamatan harus diisi.';
    }
    if (!data.kecamatan_rumah) {
      newErrors.kecamatan_rumah = 'Kecamatan rumah harus diisi.';
    }
    if (!data.kecamatan_kantor) {
      newErrors.kecamatan_kantor = 'Kecamatan kantor harus diisi.';
    }
    if (!data.kota) {
      newErrors.kota = 'Kota/Kabupaten harus diisi.';
    }
    if (!data.kota_rumah) {
      newErrors.kota_rumah = 'Kota/Kabupaten rumah harus diisi.';
    }
    if (!data.kota_kantor) {
      newErrors.kota_kantor = 'Kota/Kabupaten kantor harus diisi.';
    }
    if (!data.provinsi) {
      newErrors.provinsi = 'Provinsi harus diisi.';
    }
    if (!data.provinsi_rumah) {
      newErrors.provinsi_rumah = 'Provinsi rumah harus diisi.';
    }
    if (!data.provinsi_kantor) {
      newErrors.provinsi_kantor = 'Provinsi kantor harus diisi.';
    }
    if (!data.kodepos) {
      newErrors.kodepos = 'Kode Pos harus diisi.';
    }
    if (!data.kodepos_rumah) {
      newErrors.kodepos_rumah = 'Kode Pos rumah harus diisi.';
    }
    if (!data.kodepos_kantor) {
      newErrors.kodepos_kantor = 'Kode Pos kantor harus diisi.';
    }
    if (!data.email) {
      newErrors.email = 'Email harus diisi.';
    }
    if (!data.pekerjaan) {
      newErrors.pekerjaan = 'Pekerjaan harus diisi.';
    }
    if (!data.pemilikan) {
      newErrors.pemilikan = 'Pemilikan harus diisi.';
    }
    if (!data.perusahaan) {
      newErrors.perusahaan = 'Nama perusahaan harus diisi.';
    }
    if (!data.bidang_pekerjaan) {
      newErrors.bidang_pekerjaan = 'Bidang pekerjaan harus diisi.';
    }
    if (!data.penghasilan_tetap) {
      newErrors.penghasilan_tetap = 'Penghasilan tetap harus diisi.';
    }
    if (!data.penghasilan_tidak_tetap) {
      newErrors.penghasilan_tidak_tetap = 'Penghasilan tidak tetap harus diisi.';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      const firstErrorField = Object.keys(newErrors)[0];
      if (fieldRefs[firstErrorField] && fieldRefs[firstErrorField].current) {
        fieldRefs[firstErrorField].current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        fieldRefs[firstErrorField].current.focus();
      }
    }

    setLoading(true);

    try {
      // Prepare FormData for the first POST request
      const formData = new FormData();
      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      });

      await axios
        .post(process.env.REACT_APP_API_BASE_URL + `/pengkinian-data/${userData.username}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

      setSuccess('Data Anda telah berhasil disimpan. Kami akan melakukan proses verifikasi terlebih dahulu. Mohon untuk memeriksa secara berkala.');
      setShowModal(true);
      setError('');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Terjadi kesalahan. Silakan coba lagi.');
        console.error(error);
      }

      setTimeout(() => {
        setError('');
      }, 3000);
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Container>
      <FormWrap>
        <FormContent>
          <Form onSubmit={handleSubmit}>
            <FormH1>Identitas Peserta</FormH1>
            <FormH1Error>(*) Data wajib diisi</FormH1Error>
            <FormCardWrapper>
              <FormDiv>
                <FormLabel htmlFor="no_peserta">Nomor Peserta</FormLabel>
                <FormInput type='text' name='no_peserta' value={userData.username} onChange={handleChange} readOnly />
                <FormLabel htmlFor="nama"><strong>Nama</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormInput type='text' name='nama' value={data.nama} ref={fieldRefs.nama} onChange={handleChange} />
                <FormH2><i>Nama sesuai yang tertera di kartu identitas.</i></FormH2>
                {errors.nama && <FormH2Error>{errors.nama}</FormH2Error>}
                <FormInputWrapper>
                  <FormInputDiv>
                    <FormLabel htmlFor="no_identitas"><strong>Nomor Identitas (NIK)</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                    <FormInput id="no_identitas" type="text" name="no_identitas" value={data.no_identitas} onChange={handleChange} ref={fieldRefs.no_identitas} />
                    <FormH2><i>Sesuai kartu identitas.</i></FormH2>
                    {errors.no_identitas && <FormH2Error>{errors.no_identitas}</FormH2Error>}
                  </FormInputDiv>
                  <FormInputDiv>
                  <FormLabel htmlFor="npwp"><strong>NPWP</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                    <FormInput id="npwp" type="text" name="npwp" value={data.npwp} onChange={handleChange} ref={fieldRefs.npwp} />
                    <FormH2><i>Isi "-" jika tidak ada.</i></FormH2>
                    {errors.npwp && <FormH2Error>{errors.npwp}</FormH2Error>}
                  </FormInputDiv>
                </FormInputWrapper>
                <FormLabel htmlFor="jenis_kelamin"><strong>Jenis Kelamin</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormSelect id="jenis_kelamin" name="jenis_kelamin" value={data.jenis_kelamin} onChange={handleChange} ref={fieldRefs.jenis_kelamin} >
                  <FormOption value="" disabled>Jenis Kelamin</FormOption>
                  <FormOption value="P">Pria</FormOption>
                  <FormOption value="W">Wanita</FormOption>
                </FormSelect>
                {errors.jenis_kelamin && <FormH2Error>{errors.jenis_kelamin}</FormH2Error>}
                <FormLabel htmlFor="email"><strong>Email</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormInput id="email" type="text" name="email" value={data.email} onChange={handleChange} ref={fieldRefs.email} />
                {errors.email && <FormH2Error>{errors.email}</FormH2Error>}
                <FormInputWrapper>
                  <FormInputDiv>
                    <FormLabel htmlFor="no_hp"><strong>Nomor Telepon Genggam</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                    <FormInput id="no_hp" type="text" name="no_hp" value={data.no_hp} onChange={handleChange} ref={fieldRefs.no_hp} />
                    {errors.no_hp && <FormH2Error>{errors.no_hp}</FormH2Error>}
                  </FormInputDiv>
                  <FormInputDiv>
                    <FormLabel htmlFor="no_telp"><strong>Nomor Telepon Rumah</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                    <FormInput id="no_telp" type="text" name="no_telp" value={data.no_telp} onChange={handleChange} ref={fieldRefs.no_telp} />
                    <FormH2><i>Isi "-" jika tidak ada.</i></FormH2>
                    {errors.no_telp && <FormH2Error>{errors.no_telp}</FormH2Error>}
                  </FormInputDiv>
                </FormInputWrapper>
                <FormLabel htmlFor="ibu_kandung"><strong>Nama Gadis Ibu Kandung</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormInput id="ibu_kandung" type="text" name="ibu_kandung" value={data.ibu_kandung} onChange={handleChange} ref={fieldRefs.ibu_kandung} />
                {errors.ibu_kandung && <FormH2Error>{errors.ibu_kandung}</FormH2Error>}
                <FormLabel htmlFor="foto_ktp"><strong>Upload Foto Kartu Identitas (KTP)</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormInput id="foto_ktp" type="file" name="foto_ktp" onChange={handleFileChangeKTP} />
                {errors.dokumen_ktp && <FormH2Error>{errors.dokumen_ktp}</FormH2Error>}
                <FormH2><i>Unggah dalam format JPEG, PNG atau PDF maksimal 5 MB.</i></FormH2>
                {data.npwp && (
                  <>
                    <FormLabel htmlFor="foto_npwp">Upload Foto NPWP</FormLabel>
                    <FormInput id="foto_npwp" type="file" name="foto_npwp" onChange={handleFileChangeNPWP} />
                    {errors.dokumen_npwp && <FormH2Error>{errors.dokumen_npwp}</FormH2Error>}
                    <FormH2><i>Unggah dalam format JPEG, PNG atau PDF maksimal 5 MB.</i></FormH2>
                  </>
                )}
              </FormDiv>
              <FormDiv>
                <FormInputWrapper>
                  <FormInputDiv>
                    <FormLabel htmlFor="tempat_lahir"><strong>Tempat Lahir</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                    <FormInput id="tempat_lahir" type="text" name="tempat_lahir" value={data.tempat_lahir} onChange={handleChange} ref={fieldRefs.tempat_lahir} />
                    {errors.tempat_lahir && <FormH2Error>{errors.tempat_lahir}</FormH2Error>}
                  </FormInputDiv>
                  <FormInputDiv>
                    <FormLabel htmlFor="tanggal_lahir"><strong>Tanggal Lahir</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                    <FormInput id="tanggal_lahir" type="date" name="tanggal_lahir" value={data.tanggal_lahir} onChange={handleChange} ref={fieldRefs.tanggal_lahir} />
                    {errors.tanggal_lahir && <FormH2Error>{errors.tanggal_lahir}</FormH2Error>}
                  </FormInputDiv>
                </FormInputWrapper>
                <FormInputWrapper>
                  <FormInputDiv>
                    <FormInput id="usia_pensiun" type="text" name="usia_pensiun" value={data.usia_pensiun} onChange={handleChange} hidden />
                  </FormInputDiv>
                  <FormInputDiv>
                    <FormInput id="tanggal_registrasi" type="text" name="tanggal_registrasi" value={data.tanggal_registrasi} onChange={handleChange} hidden />
                  </FormInputDiv>
                </FormInputWrapper>
                <FormLabel htmlFor="alamat"><strong>Alamat</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormText id="alamat" name="alamat" value={data.alamat} ref={fieldRefs.alamat} onChange={handleChange} />
                {errors.alamat && <FormH2Error>{errors.alamat}</FormH2Error>}
                <FormH2><i>Alamat sesuai yang tertera di kartu identitas.</i></FormH2>
                <FormInputWrapper>
                  <FormInputDiv>
                    <FormLabel htmlFor="rtrw"><strong>RT/RW</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                    <FormInput id="rtrw" type="text" name="rtrw" value={data.rtrw} ref={fieldRefs.rtrw} onChange={handleChange} />
                    {errors.rtrw && <FormH2Error>{errors.rtrw}</FormH2Error>}
                    <FormH2><i>Isi dengan format "000/000".</i></FormH2>
                  </FormInputDiv>
                  <FormInputDiv>
                  <FormLabel htmlFor="kodepos"><strong>Kode Pos</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                    <FormInput id="kodepos" type="text" name="kodepos" value={data.kodepos} ref={fieldRefs.kodepos} onChange={handleChange} />
                    {errors.kodepos && <FormH2Error>{errors.kodepos}</FormH2Error>}
                  </FormInputDiv>
                </FormInputWrapper>
                <FormLabel htmlFor="provinsi"><strong>Provinsi</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormSelect id="provinsi" name="provinsi" onChange={handleProvinsiChange} value={data.provinsi} ref={fieldRefs.provinsi}>
                  <FormOption value="" disabled>Provinsi</FormOption>
                  {provinces.map(province => (
                    <FormOption key={province.id} value={province.name}>{province.name}</FormOption>
                  ))}
                </FormSelect>
                {errors.provinsi && <FormH2Error>{errors.provinsi}</FormH2Error>}
                <FormLabel htmlFor="kota"><strong>Kabupaten/Kota</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormSelect id="kota" name="kota" onChange={handleKotaChange} value={data.kota} ref={fieldRefs.kota}>
                  <FormOption value="" disabled>Kabupaten/Kota</FormOption>
                  {districts.map(city => (
                    <FormOption key={city.id} value={city.name}>{city.name}</FormOption>
                  ))}
                </FormSelect>
                {errors.kota && <FormH2Error>{errors.kota}</FormH2Error>}
                <FormLabel htmlFor="kecamatan"><strong>Kecamatan</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormSelect id="kecamatan" name="kecamatan" onChange={handleKecamatanChange} value={data.kecamatan} ref={fieldRefs.kecamatan}>
                  <FormOption value="" disabled>Kecamatan</FormOption>
                  {subdistricts.map(kecamatan => (
                    <FormOption key={kecamatan.id} value={kecamatan.name}>{kecamatan.name}</FormOption>
                  ))}
                </FormSelect>
                {errors.kecamatan && <FormH2Error>{errors.kecamatan}</FormH2Error>}
                <FormLabel htmlFor="kelurahan"><strong>Desa/Kelurahan</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormSelect id="kelurahan" name="kelurahan" onChange={handleKelurahanChange} value={data.kelurahan} ref={fieldRefs.kelurahan}>
                  <FormOption value="" disabled>Kelurahan</FormOption>
                  {villages.map(kelurahan => (
                    <FormOption key={kelurahan.id} value={kelurahan.name}>{kelurahan.name}</FormOption>
                  ))}
                </FormSelect>
                {errors.kelurahan && <FormH2Error>{errors.kelurahan}</FormH2Error>}
              </FormDiv>
            </FormCardWrapper>

            <FormH1>Tempat Tinggal Peserta</FormH1>
            <FormH1Error>(*) Data wajib diisi</FormH1Error>
            <FormCardWrapper>
              <FormDiv>
                <FormLabel htmlFor="alamat_rumah"><strong>Alamat Rumah</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormAgreeLeft>
                  <FormInput type='checkbox' id='samaDenganKTP' name='samaDenganKTP' checked={data.samaDenganKTP} onChange={handleChange}/>
                  <FormLabel htmlFor='samaDenganKTP'><i>Alamat domisili tempat tinggal sama dengan alamat yang tertera di kartu identitas.</i></FormLabel>
                </FormAgreeLeft>
                <FormText id="alamat_rumah" name="alamat_rumah" value={data.alamat_rumah} ref={fieldRefs.alamat_rumah} onChange={handleChange} disabled={data.samaDenganKTP}/>
                {errors.alamat_rumah && <FormH2Error>{errors.alamat_rumah}</FormH2Error>}
                <FormH2><i>Alamat sesuai dengan domisili tempat tinggal saat ini.</i></FormH2>
                <FormInputWrapper>
                  <FormInputDiv>
                    <FormLabel htmlFor="rtrw_rumah"><strong>RT/RW</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                    <FormInput id="rtrw_rumah" type="text" name="rtrw_rumah" value={data.rtrw_rumah} ref={fieldRefs.rtrw_rumah} onChange={handleChange} disabled={data.samaDenganKTP}/>
                    {errors.rtrw_rumah && <FormH2Error>{errors.rtrw_rumah}</FormH2Error>}
                    <FormH2><i>Isi dengan format "000/000".</i></FormH2>
                  </FormInputDiv>
                  <FormInputDiv>
                    <FormLabel htmlFor="kodepos_rumah"><strong>Kode Pos</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                    <FormInput id="kodepos_rumah" type="text" name="kodepos_rumah" value={data.kodepos_rumah} ref={fieldRefs.kodepos_rumah} onChange={handleChange} disabled={data.samaDenganKTP}/>
                    {errors.kodepos_rumah && <FormH2Error>{errors.kodepos_rumah}</FormH2Error>}
                  </FormInputDiv>
                </FormInputWrapper>
                <FormLabel htmlFor="provinsi_rumah"><strong>Provinsi</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                {data.samaDenganKTP ? (
                  <FormInput id="provinsi_rumah" name="provinsi_rumah" onChange={handleChange} value={data.provinsi_rumah} disabled={data.samaDenganKTP} />
                ) : (
                  <FormSelect id="provinsi_rumah" name="provinsi_rumah" onChange={handleProvinsiRumahChange} ref={fieldRefs.provinsi_rumah} value={data.provinsi_rumah}>
                    <FormOption value='' disabled>Provinsi</FormOption>
                    {provinces.map(province => (
                      <FormOption key={province.id} value={province.name}>{province.name}</FormOption>
                    ))}
                  </FormSelect>
                  )}
                {errors.provinsi_rumah && <FormH2Error>{errors.provinsi_rumah}</FormH2Error>}
              </FormDiv>
              <FormDiv>
                <FormLabel htmlFor="kota_rumah"><strong>Kabupaten/Kota</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                {data.samaDenganKTP ? (
                  <FormInput id="kota_rumah" name="kota_rumah" onChange={handleChange} value={data.kota_rumah} disabled={data.samaDenganKTP} />
                ) : (
                  <FormSelect id="kota_rumah" name="kota_rumah" onChange={handleKotaRumahChange} value={data.kota_rumah} ref={fieldRefs.kota_rumah}>
                    <FormOption value="" disabled>Kabupaten/Kota</FormOption>
                    {districtsRumah.map(city => (
                      <FormOption key={city.id} value={city.name}>{city.name}</FormOption>
                      ))}
                  </FormSelect>
                )}
                {errors.kota_rumah && <FormH2Error>{errors.kota_rumah}</FormH2Error>}
                <FormLabel htmlFor="kecamatan_rumah"><strong>Kecamatan</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                {data.samaDenganKTP ? (
                  <FormInput id="kecamatan_rumah" name="kecamatan_rumah" onChange={handleChange} value={data.kecamatan_rumah} disabled={data.samaDenganKTP} />
                  ) : (
                    <FormSelect id="kecamatan_rumah" name="kecamatan_rumah" onChange={handleKecamatanRumahChange} value={data.kecamatan_rumah} ref={fieldRefs.kecamatan_rumah}>
                      <FormOption value="" disabled>Kecamatan</FormOption>
                      {subdistrictsRumah.map(kecamatan => (
                        <FormOption key={kecamatan.id} value={kecamatan.name}>{kecamatan.name}</FormOption>
                      ))}
                    </FormSelect>
                  )}
                {errors.kecamatan_rumah && <FormH2Error>{errors.kecamatan_rumah}</FormH2Error>}
                <FormLabel htmlFor="kelurahan_rumah"><strong>Desa/Kelurahan</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                {data.samaDenganKTP ? (
                  <FormInput id="kelurahan_rumah" name="kelurahan_rumah" onChange={handleChange} value={data.kelurahan_rumah} disabled={data.samaDenganKTP} />
                  ) : (
                    <FormSelect id="kelurahan_rumah" name="kelurahan_rumah" onChange={handleKelurahanRumahChange} value={data.kelurahan_rumah} ref={fieldRefs.kelurahan_rumah}>
                      <FormOption value="" disabled>Desa/Kelurahan</FormOption>
                      {villagesRumah.map(kelurahan => (
                        <FormOption key={kelurahan.id} value={kelurahan.name}>{kelurahan.name}</FormOption>
                      ))}
                    </FormSelect>
                  )}
                {errors.kelurahan_rumah && <FormH2Error>{errors.kelurahan_rumah}</FormH2Error>}
              </FormDiv>
            </FormCardWrapper>

            <FormH1>Data Pekerjaan</FormH1>
            <FormH1Error>(*) Data wajib diisi</FormH1Error>
            <FormCardWrapper>
              <FormDiv>
                <FormLabel htmlFor="pekerjaan"><strong>Pekerjaan</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormSelect id="pekerjaan" name="pekerjaan" value={data.pekerjaan} onChange={handleChange} ref={fieldRefs.pekerjaan} >
                  <FormOption value="" disabled>Pekerjaan</FormOption>
                  {pekerjaanData && pekerjaanData.map((pekerjaan) => (
                    <FormOption key={pekerjaan.id} value={pekerjaan.code}>{pekerjaan.name}</FormOption>
                  ))}
                </FormSelect>
                {errors.pekerjaan && <FormH2Error>{errors.pekerjaan}</FormH2Error>}
                <FormLabel htmlFor="perusahaan"><strong>Nama Perusahaan</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormInput id="perusahaan" type="text" name="perusahaan" value={data.perusahaan} onChange={handleChange} ref={fieldRefs.perusahaan} />
                {errors.perusahaan && <FormH2Error>{errors.perusahaan}</FormH2Error>}
                <FormLabel htmlFor="pemilikan"><strong>Kepemilikan</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormSelect id="pemilikan" name="pemilikan" value={data.pemilikan} onChange={handleChange} ref={fieldRefs.pemilikan}>
                  <FormOption value="" disabled>Kepemilikan</FormOption>
                  {pemilikanData && pemilikanData.map((pemilikan) => (
                    <FormOption key={pemilikan.id} value={pemilikan.code}>{pemilikan.name}</FormOption>
                  ))}
                </FormSelect>
                {errors.pemilikan && <FormH2Error>{errors.pemilikan}</FormH2Error>}
                <FormLabel htmlFor="bidang_pekerjaan"><strong>Bidang Pekerjaan</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormSelect id="bidang_pekerjaan" name="bidang_pekerjaan" value={data.bidang_pekerjaan} onChange={handleChange} ref={fieldRefs.bidang_pekerjaan}>
                <FormOption value="" disabled>Bidang Pekerjaan</FormOption>
                  {usahaData && usahaData.map((usaha) => (
                    <FormOption key={usaha.id} value={usaha.code}>{usaha.nama}</FormOption>
                  ))}
                </FormSelect>
                {errors.bidang_pekerjaan && <FormH2Error>{errors.bidang_pekerjaan}</FormH2Error>}
                <FormLabel htmlFor="penghasilan_tetap"><strong>Penghasilan Tetap (Bulan)</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormSelect id="penghasilan_tetap" name="penghasilan_tetap" value={data.penghasilan_tetap} onChange={handleChange} ref={fieldRefs.penghasilan_tetap} >
                  <FormOption value="" disabled>Penghasilan</FormOption>
                  {penghasilanData && penghasilanData.map((penghasilan) => (
                    <FormOption key={penghasilan.id} value={penghasilan.code}>{penghasilan.name}</FormOption>
                  ))}
                </FormSelect>
                {errors.penghasilan_tetap && <FormH2Error>{errors.penghasilan_tetap}</FormH2Error>}
                <FormLabel htmlFor="penghasilan_tidak_tetap"><strong>Penghasilan Tidak Tetap (Bulan)</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormSelect id="penghasilan_tidak_tetap" name="penghasilan_tidak_tetap" value={data.penghasilan_tidak_tetap} onChange={handleChange} ref={fieldRefs.penghasilan_tidak_tetap} >
                  <FormOption value="" disabled>Penghasilan</FormOption>
                  {penghasilanTidakTetapData && penghasilanTidakTetapData.map((penghasilan) => (
                    <FormOption key={penghasilan.id} value={penghasilan.code}>{penghasilan.name}</FormOption>
                  ))}
                </FormSelect>
                {errors.penghasilan_tidak_tetap && <FormH2Error>{errors.penghasilan_tidak_tetap}</FormH2Error>}
              </FormDiv>
              <FormDiv>
                <FormLabel htmlFor="alamat_kantor"><strong>Alamat Kantor</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormText id="alamat_kantor" name="alamat_kantor" value={data.alamat_kantor} ref={fieldRefs.alamat_kantor} onChange={handleChange} />
                {errors.alamat_kantor && <FormH2Error>{errors.alamat_kantor}</FormH2Error>}
                <FormLabel htmlFor="kodepos_kantor"><strong>Kode Pos</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormInput id="kodepos_kantor" type="text" name="kodepos_kantor" value={data.kodepos_kantor} ref={fieldRefs.kodepos_kantor} onChange={handleChange} />
                {errors.kodepos_kantor && <FormH2Error>{errors.kodepos_kantor}</FormH2Error>}
                <FormLabel htmlFor="provinsi_kantor"><strong>Provinsi</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormSelect id="provinsi_kantor" name="provinsi_kantor" onChange={handleProvinsiKantorChange} value={data.provinsi_kantor} ref={fieldRefs.provinsi_kantor}>
                  <FormOption value="" disabled>Provinsi</FormOption>
                  {provinces.map(province => (
                    <FormOption key={province.id} value={province.name}>{province.name}</FormOption>
                  ))}
                </FormSelect>
                {errors.provinsi_kantor && <FormH2Error>{errors.provinsi_kantor}</FormH2Error>}
                <FormLabel htmlFor="kota_kantor"><strong>Kabupaten/Kota</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormSelect id="kota_kantor" name="kota_kantor" onChange={handleKotaKantorChange} value={data.kota_kantor} ref={fieldRefs.kota_kantor}>
                  <FormOption value="" disabled>Kabupaten/Kota</FormOption>
                  {districtsKantor.map(city => (
                    <FormOption key={city.id} value={city.name}>{city.name}</FormOption>
                  ))}
                </FormSelect>
                {errors.kota_kantor && <FormH2Error>{errors.kota_kantor}</FormH2Error>}
                <FormLabel htmlFor="kecamatan_kantor"><strong>Kecamatan</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormSelect id="kecamatan_kantor" name="kecamatan_kantor" onChange={handleKecamatanKantorChange} value={data.kecamatan_kantor} ref={fieldRefs.kecamatan_kantor}>
                  <FormOption value="" disabled>Kecamatan</FormOption>
                  {subdistrictsKantor.map(kecamatan => (
                    <FormOption key={kecamatan.id} value={kecamatan.name}>{kecamatan.name}</FormOption>
                  ))}
                </FormSelect>
                {errors.kecamatan_kantor && <FormH2Error>{errors.kecamatan_kantor}</FormH2Error>}
                <FormLabel htmlFor="kelurahan_kantor"><strong>Desa/Kelurahan</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                <FormSelect id="kelurahan_kantor" name="kelurahan_kantor" onChange={handleKelurahanKantorChange} value={data.kelurahan_kantor} ref={fieldRefs.kelurahan_kantor}>
                  <FormOption value="" disabled>Kelurahan</FormOption>
                  {villagesKantor.map(kelurahan => (
                    <FormOption key={kelurahan.id} value={kelurahan.name}>{kelurahan.name}</FormOption>
                  ))}
                </FormSelect>
                {errors.kelurahan_kantor && <FormH2Error>{errors.kelurahan_kantor}</FormH2Error>}
              </FormDiv>
            </FormCardWrapper>

            <FormH1>Pernyataan Peserta</FormH1>
            <FormH3>
              Dengan menekan tombol <b>Simpan</b> atau pernyataan serupa yang tersedia di halaman ini, saya dengan sadar dan tanpa paksaan, setuju dan menyetujui:
              <ol>
                <li>Bahwa data yang saya berikan adalah benar dan lengkap, apabila terdapat perbedaan data, maka data yang benar adalah data yang tertera pada dokumen yang dilampirkan.</li>
                <li>Bahwa DPLK Syariah Muamalat terlepas dari segala tanggung jawab atas kesalahan terkait data yang diberikan peserta.</li>
                <li>Bahwa DPLK Syariah Muamalat memiliki izin sepenuhnya, apabila diperlukan, untuk mengungkapkan data nasabah kepada pihak lain di dalam atau di luar negeri yang terikat dengan DPLK Syariah Muamalat dengan tetap memperhatikan ketentuan hukum yang berlaku mengenai kerahasiaan data nasabah.</li>
                <li>Bahwa saya mematuhi, tunduk dan taat kepada seluruh ketentuan perundang-undangan yang berlaku di sektor jasa keuangan.</li>
              </ol>
            </FormH3>
            <FormDiv>
              <FormRupiahLeft>
                <FormInput type='checkbox' id='checkbox_agree' name='checkbox_agree' checked={isCheckboxChecked} onChange={handleCheckboxChange} />
                <FormLabel htmlFor='checkbox_agree'>Saya telah membaca dan menyetujui ketentuan yang berlaku.</FormLabel>
              </FormRupiahLeft>
              {error && (
                <ErrorCard>
                  <MessageH1><b>Gagal!</b></MessageH1>
                  <MessageH2>{error}</MessageH2>
                </ErrorCard>
              )}
              {success && (
                <SuccessModal show={showModal} onLogin={() => {
                  history.push('/peserta/profil/');
                  scroll.scrollToTop();
                }} message={success} />
              )}
              {showButton && (
                <FormButton type="submit" disabled={loading}>{loading ? 'Loading...' : 'Simpan'}</FormButton>
              )}
            </FormDiv>
          </Form>
        </FormContent>
      </FormWrap>
    </Container>
    </>
  )

};

export default Pengkinian;
