import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { format, isValid } from 'date-fns';
import { id as localeID } from 'date-fns/locale';
import { Table } from 'react-bootstrap';
import {
  RegisPerusahaanContainer,
  RegisPerusahaanWrapper
} from './RegisPerusahaanElements';
import '../../../style.css';

const RegisPerusahaanPage = ({userData}) => {
  const [state, setState] = useState({
    data: [],
    isMobile: window.innerWidth <= 820, // Menentukan apakah tampilan saat ini adalah mobile
    showEditForm: false,
    showRejectForm: false,
    editData: {
      id: null,
      nik: '',
      email: ''
    },
  });
  const cookies = new Cookies();
  const token = cookies.get('token');

  useEffect(() => {
    fetchData();
    window.addEventListener('resize', handleWindowSizeChange); // Menambahkan event listener untuk menghandle perubahan ukuran layar
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange); // Membersihkan event listener saat komponen dilepas
    };
  }, []);

  const handleWindowSizeChange = () => {
    setState((prev) => ({
      ...prev,
      isMobile: window.innerWidth <= 820 // Mengupdate status isMobile saat terjadi perubahan ukuran layar
    }));
  };

  const fetchData = () => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + `/registrasi-perusahaan`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const modifiedData = res.data.content.map((item) => {
          let statusText;
          if (item.status === false || item.status === 0) {
            statusText = 'Belum direspon';
          } else if (item.status === true || item.status === 1) {
            statusText = 'Sudah direspon';
          }
          return {
            ...item,
            status: statusText,
            createdAt: isValid(new Date(item.createdAt)) ? format(new Date(item.createdAt), 'dd MMMM yyyy', { locale: localeID }) : '',
          };
        });
        setState((prev) => ({
          ...prev,
          data: modifiedData
        }));
      })
      .catch((error) => {
        console.error(error.response.data.message);
      });
  };

  return (
    <RegisPerusahaanContainer id="registrasi-perusahaan">
      {!state.isMobile && ( // Hanya menampilkan tabel dan pagination jika bukan tampilan mobile
        <>
          <RegisPerusahaanWrapper>
            <Table striped bordered hover className="registrasi-perusahaan">
              <thead>
                <tr>
                  <th>Nama Perusahaan</th>
                  <th>Nama Penanggung Jawab</th>
                  <th>Jabatan</th>
                  <th>Email</th>
                  <th>Nomor Telepon/Hp</th>
                  <th>Tanggal Email</th>
                </tr>
              </thead>
              <tbody>
                {state.data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.nama}</td>
                    <td>{item.pic}</td>
                    <td>{item.jabatan}</td>
                    <td>{item.email}</td>
                    <td>{item.no_telepon}</td>
                    <td>{item.createdAt}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </RegisPerusahaanWrapper>
        </>
      )}
      {state.isMobile && ( // Hanya menampilkan nama dan status jika tampilan mobile
        <>
          <RegisPerusahaanWrapper>
            <Table striped bordered hover className="registrasi">
              <thead>
                <tr>
                  <th>Nama</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {state.data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.nama}</td>
                    <td>{item.status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </RegisPerusahaanWrapper>
        </>
      )}
    </RegisPerusahaanContainer>
  );
};

export default RegisPerusahaanPage;
