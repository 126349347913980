import React from 'react';
import {
  CloseIcon,
  Heading,
  Icon,
  SidebarContainer,
  SidebarLink,
  SidebarMenu,
  SidebarRoute1,
  SidebarRoute2,
  SidebarSubMenu,
  SidebarWrapper,
  SideBtnWrap,
} from './SidebarElements';
import { animateScroll as scroll } from 'react-scroll';
import { trackClick } from '../../utils/api';

const Sidebar = ({ isOpen, toggle }) => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <Heading><b>Tentang Kami</b></Heading>
          <SidebarSubMenu>
            <SidebarLink to="/about/dplk/" onClick={() => { toggleHome(); trackClick("Tentang DPLK"); }}>
              Tentang DPLK
            </SidebarLink>
            <SidebarLink to="/about/manajemen/" onClick={() => { toggleHome(); trackClick("Manajemen"); }}>
              Manajemen
            </SidebarLink>
            <SidebarLink to="/about/lokasi/" onClick={() => { toggleHome(); trackClick("Lokasi Kantor"); }}>
              Lokasi Kantor
            </SidebarLink>
            <SidebarLink to="/about/penghargaan/" onClick={() => { toggleHome(); trackClick("Penghargaan"); }}>
              Penghargaan
            </SidebarLink>
          </SidebarSubMenu>
        </SidebarMenu>
        <SidebarMenu>
          <Heading><b>Produk Individu</b></Heading>
          <SidebarSubMenu>
            <SidebarLink to="/product/pensiun-hijrah/" onClick={() => { toggleHome(); trackClick("Pensiun Hijrah"); }}>
              Pensiun Hijrah
            </SidebarLink>
          </SidebarSubMenu>
        </SidebarMenu>
        <SidebarMenu>
          <Heading><b>Produk Non-Individu</b></Heading>
          <SidebarSubMenu>
            <SidebarLink to="/product/pensiun-hijrah/" onClick={() => { toggleHome(); trackClick("Pensiun Hijrah"); }}>
              Pensiun Hijrah
            </SidebarLink>
            <SidebarLink to="/product/pensiun-hijrah-pasca-kerja/" onClick={() => { toggleHome(); trackClick("Pensiun Hijrah Pasca Kerja"); }}>
              Pensiun Hijrah Pasca Kerja
            </SidebarLink>
            <SidebarLink to="/product/pensiun-hijrah-eksekutif/" onClick={() => { toggleHome(); trackClick("Pensiun Hijrah Eksekutif"); }}>
            Pensiun Hijrah Eksekutif
            </SidebarLink>
          </SidebarSubMenu>
        </SidebarMenu>
        <SidebarMenu>
          <Heading><b>Produk Lainnya</b></Heading>
          <SidebarSubMenu>
            <SidebarLink to="/product/zakat-dana-pensiun/" onClick={() => { toggleHome(); trackClick("Zakat Dana Pensiun"); }}>
              Zakat Dana Pensiun
            </SidebarLink>
          </SidebarSubMenu>
        </SidebarMenu>
        <SidebarMenu>
          <Heading><b>Simulasi</b></Heading>
          <SidebarSubMenu>
            <SidebarLink to="/simulasi/" onClick={() => { toggleHome(); trackClick("Simulasi Berdasarkan Iuran"); }}>
              Berdasarkan Iuran
            </SidebarLink>
            <SidebarLink to="/simulasi/based-on-needs/" onClick={() => { toggleHome(); trackClick("Simulasi Berdasarkan Kebutuhan"); }}>
              Berdasarkan Kebutuhan
            </SidebarLink>
          </SidebarSubMenu>
        </SidebarMenu>
        <SidebarMenu>
          <Heading><b>Pusat Media</b></Heading>
          <SidebarSubMenu>
            <SidebarLink to="/media/investasi/" onClick={() => { toggleHome(); trackClick("Ringkasan Investasi"); }}>
              Ringkasan Investasi<br/>(Fund Fact Sheet)
            </SidebarLink>
            <SidebarLink to="/media/keuangan/" onClick={() => { toggleHome(); trackClick("Laporan Keuangan"); }}>
              Laporan Keuangan
            </SidebarLink>
            <SidebarLink to="/media/dewan-pengawas/" onClick={() => { toggleHome(); trackClick("Laporan Dewan Pengawas"); }}>
              Laporan Dewan Pengawas
            </SidebarLink>
            <SidebarLink to="/media/berita/" onClick={() => { toggleHome(); trackClick("Berita"); }}>
              Berita
            </SidebarLink>
            <SidebarLink to="/media/pengumuman/" onClick={() => { toggleHome(); trackClick("Pengumuman"); }}>
              Pengumuman
            </SidebarLink>
            <SidebarLink to="/media/video/" onClick={() => { toggleHome(); trackClick("Video"); }}>
              Video
            </SidebarLink>
          </SidebarSubMenu>
        </SidebarMenu>
        <SidebarMenu>
          <Heading><b>Bantuan</b></Heading>
          <SidebarSubMenu>
            <SidebarLink to="/bantuan/panduan/" onClick={() => { toggleHome(); trackClick("Panduan"); }}>
              Panduan
            </SidebarLink>
            <SidebarLink to="/bantuan/download/" onClick={() => { toggleHome(); trackClick("Download"); }}>
              Download
            </SidebarLink>
            <SidebarLink to="/bantuan/faq/" onClick={() => { toggleHome(); trackClick("Sering Ditanyakan"); }}>
              Sering Ditanyakan
            </SidebarLink>
            <SidebarLink to="/bantuan/kontak/" onClick={() => { toggleHome(); trackClick("Hubungi Kami"); }}>
              Hubungi Kami
            </SidebarLink>
          </SidebarSubMenu>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute1 to="/peserta/" onClick={() => { toggleHome(); trackClick("Login Peserta"); }}>Cek Saldo</SidebarRoute1>
          <SidebarRoute2 to="/peserta/registrasi" onClick={() => { toggleHome(); trackClick("Registrasi"); }}>Registrasi</SidebarRoute2>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
