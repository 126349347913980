import React, { useState, useEffect } from 'react';
import { utils, writeFile } from 'xlsx';
import {
  InvestContainer,
  InvestWrapper,
  InvestCard,
  InvestH4,
  InvestH3,
  InvestH2,
  InvestH1,
  FormButton,
  TableWrapper,
} from './InvestElements';
import Table from 'react-bootstrap/Table';
import '../../../style.css';

const HasilInvestasi = ({ hasilInvestasi, data }) => {
  const [isMobile, setIsMobile] = useState(false);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR'
    }).format(amount);
  };

  const exportToExcel = () => {
    // Mapping data with custom header names
    const mappedData = data.map(row => ({
      'Tahun': row.tahun,
      'Iuran Bulanan': row.iuranBulanan,
      'Iuran Tahunan': row.iuranTahunan,
      'Akumulasi Iuran': row.akumulasiIuran,
      'Akumulasi Sebelum Biaya': row.akumulasiSebelumBiaya,
      'Akumulasi Setelah Biaya': row.akumulasiSetelahBiaya,
    }));

    const ws = utils.json_to_sheet(mappedData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Hasil Investasi");
    writeFile(wb, "Hasil_Investasi.xlsx");
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check on component mount

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <InvestContainer id="result">
      <InvestH1><b>Hasil Simulasi*</b></InvestH1>
      <InvestH2>*) Hasil simulasi merupakan estimasi, hasil akhir sebenarnya dapat berbeda.</InvestH2>
      {isMobile && (
        <FormButton onClick={exportToExcel}>Rincian Hasil Simulasi</FormButton>
      )}
      {!isMobile && (
        <TableWrapper>
          <Table striped bordered hover className="simulasi">
            <thead>
              <tr>
                <th>Tahun</th>
                <th>Iuran Bulanan</th>
                <th>Iuran Tahunan</th>
                <th>Akumulasi Iuran</th>
                <th>Saldo (Sebelum Dipotong Biaya)</th>
                <th>Akumulasi (Setelah Dipotong Biaya)</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => {
                let keterangan = "";
                switch (index + 1) {
                  case 1:
                    keterangan = "*";
                    break;
                  case data.length:
                    keterangan = "**";
                    break;
                  default:
                    keterangan = "";
                }

                return (
                <tr key={index}>
                  <td>{row.tahun}{keterangan}</td>
                  <td>{formatCurrency(row.iuranBulanan)}</td>
                  <td>{formatCurrency(row.iuranTahunan)}</td>
                  <td>{formatCurrency(row.akumulasiIuran)}</td>
                  <td>{formatCurrency(row.akumulasiSebelumBiaya)}</td>
                  <td>{formatCurrency(row.akumulasiSetelahBiaya)}</td>
                </tr>
                );
              })}
            </tbody>
          </Table>
          <InvestH4>*) Tahun pertama dimulai sejak bulan terdaftar s/d akhir tahun (Desember).</InvestH4>
          <InvestH4>**) Tahun terakhir dimulai sejak awal tahun (Januari) s/d bulan kelahiran.</InvestH4>
        </TableWrapper>
      )}
      <InvestWrapper>
        <InvestCard>
          <InvestH2>Total Iuran</InvestH2>
          <InvestH3><b>{formatCurrency(hasilInvestasi.akumulasiIuran)}</b></InvestH3>
          <InvestH4><i>Total iuran adalah dana awal ditambah iuran mulai dari awal kepesertaan hingga berakhirnya masa kepesertaan (sesuai usia pensiun).</i></InvestH4>
        </InvestCard>
        <InvestCard>
          <InvestH2>Hasil Pengembangan</InvestH2>
          <InvestH3><b>{formatCurrency(hasilInvestasi.hasilPengembangan)}</b></InvestH3>
          <InvestH4><i>Hasil pengembangan adalah jumlah perkembangan total iuran dikurangi biaya administrasi dan pengelolaan.</i></InvestH4>
        </InvestCard>
        <InvestCard>
          <InvestH2>Total Dana Manfaat</InvestH2>
          <InvestH3><b>{formatCurrency(hasilInvestasi.totalDana)}</b></InvestH3>
          <InvestH4><i>Total dana manfaat adalah dana yang bisa diklaim di akhir masa kepesertaan (sesuai usia pensiun).</i></InvestH4>
        </InvestCard>
        <InvestCard>
          <InvestH2>Total Pajak</InvestH2>
          <InvestH3><b>{formatCurrency(hasilInvestasi.pajak)}</b></InvestH3>
          <InvestH4><i>Total pajak adalah pajak yang harus dibayarkan untuk manfaat pensiun. Sesuai regulasi, tarif pajak sebesar 5% jika total dana manfaat di atas Rp50.000.000.</i></InvestH4>
        </InvestCard>
        <InvestCard>
          <InvestH2>Total Dana Manfaat (Setelah Pajak)</InvestH2>
          <InvestH3><b>{formatCurrency(hasilInvestasi.totalDanaPajak)}</b></InvestH3>
          <InvestH4><i>Total dana manfaat adalah dana yang bisa diklaim di akhir masa kepesertaan (sesuai usia pensiun) setelah dipotong pajak.</i></InvestH4>
        </InvestCard>
      </InvestWrapper>
    </InvestContainer>
  );
};

export default HasilInvestasi;
