import React from 'react';
import DPLK from '../../../assets/images/DPLK.png';
import BMI from '../../../assets/images/BMI.png';
import BPKH from '../../../assets/images/BPKH.png';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaWhatsapp
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterWrap,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
  SocialLogoIcon,
  FindUsContainer,
  FindUsWrapper,
  FindUsColumn,
  SocialIconsColumn,
} from './FooterElements';
import { animateScroll as scroll } from 'react-scroll';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <FindUsContainer>
          <FindUsWrapper>
            <FindUsColumn>
              <SocialIconsColumn>
                <SocialLogo to="/" onClick={toggleHome}>
                  <SocialLogoIcon src={DPLK} />
                </SocialLogo>
                <SocialIconLink
                  href='https://www.bankmuamalat.co.id/'
                  target="_blank"
                  arial-label="BMI"
                >
                  <SocialLogoIcon src={BMI} />
                </SocialIconLink>
                <SocialIconLink
                  href='https://bpkh.go.id/'
                  target="_blank"
                  arial-label="BPKH"
                >
                  <SocialLogoIcon src={BPKH} />
                </SocialIconLink>
              </SocialIconsColumn>
            </FindUsColumn>
            <FindUsColumn>
              <SocialIcons>
                <SocialIconLink
                  href="https://www.facebook.com/dplksyariah.muamalat.5"
                  target="_blank"
                  arial-label="Facebook">
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.instagram.com/dplksyariahmuamalat/"
                  target="_blank"
                  arial-label="Instagram"
                >
                  <FaInstagram />
                </SocialIconLink>
                <SocialIconLink
                  href="https://youtube.com/@dplksyariahmuamalat3802?si=um9rfefCu_6gvJYI"
                  target="_blank"
                  arial-label="Youtube"
                >
                  <FaYoutube />
                </SocialIconLink>
                <SocialIconLink
                  href="https://wa.me/6281333393820"
                  target="_blank"
                  arial-label="WhatsApp"
                >
                  <FaWhatsapp />
                </SocialIconLink>
              </SocialIcons>
            </FindUsColumn>
          </FindUsWrapper>
        </FindUsContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <WebsiteRights>
              DPLK Syariah Muamalat terdaftar dan diawasi oleh Otoritas Jasa Keuangan (OJK)<br/>
              Pensiun Hijrah © {new Date().getFullYear()} All rights reserved.
            </WebsiteRights>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
