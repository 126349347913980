import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Logo from '../../../assets/images/LogoPensiunHijrahWidth.png';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import {
  MobileIcons,
  Nav,
  NavbarContainer,
  NavBtn,
  NavBtnLink,
  NavItems,
  Navlogo,
  NavMenu,
  NavIcon,
  NavLinks,
  NavLinksMedia
} from './NavbarElements';
import { NavDropdown } from 'react-bootstrap';
import { animateScroll as scroll } from 'react-scroll';
import LogoutModal from '../Modal/Logout';
import { trackClick } from '../../../utils/api';

const Navbar = ({ toggle, userData }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
    return () => window.removeEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const handleLogout = async () => {
    const cookies = new Cookies();

    try {
      await axios.post(process.env.REACT_APP_API_BASE_URL + '/logout', { withCredentials: true });

      // Clear localStorage and remove cookies
      localStorage.clear();
      cookies.remove('token', { path: '/' });
      cookies.remove('username', { path: '/' });
      cookies.remove('role', { path: '/' });
      cookies.remove('id', { path: '/' });

      // Show logout modal
      setShowModal(true);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Terjadi kesalahan. Silakan coba lagi.');
      }
    }
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <Navlogo to="/peserta/dashboard/" onClick={() => { toggleHome(); trackClick("Home Peserta"); }}>
              <NavIcon src={Logo} />
            </Navlogo>
            <MobileIcons onClick={toggle}>
              <FaBars />
            </MobileIcons>
            <NavMenu>
              <NavItems>
                <NavDropdown title="Peserta">
                  <NavLinks to="/peserta/profil/" onClick={() => { toggleHome(); trackClick("Profil Peserta"); }} userData={userData}>Profil</NavLinks>
                  <NavLinks to="/peserta/pengkinian-data/" onClick={() => { toggleHome(); trackClick("Pengkinian Data"); }} userData={userData}>Pengkinian Data</NavLinks>
                  <NavLinks to="/peserta/life-cycle-fund/" onClick={() => { toggleHome(); trackClick("Life Cycle Fund"); }} userData={userData}>Life Cycle Fund</NavLinks>
                  <NavLinks to="/peserta/paket-investasi/" onClick={() => { toggleHome(); trackClick("Ubah Paket Investasi"); }} userData={userData}>Ubah Paket Investasi</NavLinks>
                  <NavLinks to="/peserta/usia-pensiun/" onClick={() => { toggleHome(); trackClick("Ubah Usia Pensiun"); }} userData={userData}>Ubah Usia Pensiun</NavLinks>
                  <NavLinks to="/peserta/ubah-password/" onClick={() => { toggleHome(); trackClick("Ubah Password Peserta"); }} userData={userData}>Ubah Password</NavLinks>
                </NavDropdown>
              </NavItems>
              <NavItems>
                <NavDropdown title="Transaksi">
                  <NavLinks to="/peserta/saldo/" onClick={() => { toggleHome(); trackClick("Cek Saldo Peserta"); }} userData={userData}>Cek Saldo</NavLinks>
                  <NavLinks to="/peserta/riwayat/" onClick={() => { toggleHome(); trackClick("Riwayat Transaksi Peserta"); }} userData={userData}>Riwayat Transaksi</NavLinks>
                  <NavLinks to="/peserta/klaim/" onClick={() => { toggleHome(); trackClick("Status Klaim"); }} userData={userData}>Status Klaim</NavLinks>
                </NavDropdown>
              </NavItems>
              <NavItems>
                <NavDropdown title="Simulasi">
                  <NavLinks to="/simulasi/" onClick={() => { toggleHome(); trackClick("Simulasi Berdasarkan Iuran"); }}>Berdasarkan Iuran</NavLinks>
                  <NavLinks to="/simulasi/based-on-needs/" onClick={() => { toggleHome(); trackClick("Simulasi Berdasarkan Kebutuhan"); }}>Berdasarkan Kebutuhan</NavLinks>
                </NavDropdown>
              </NavItems>
              <NavItems>
                <NavDropdown title="Pusat Media">
                  <NavLinksMedia to="/media/investasi/" onClick={() => { toggleHome(); trackClick("Ringkasan Investasi"); }}>Ringkasan Investasi (Fund Fact Sheet)</NavLinksMedia>
                  <NavLinksMedia to="/media/keuangan/" onClick={() => { toggleHome(); trackClick("Laporan Keuangan"); }}>Laporan Keuangan</NavLinksMedia>
                  <NavLinksMedia to="/media/dewan-pengawas/" onClick={() => { toggleHome(); trackClick("Laporan Dewan Pengawas"); }}>Laporan Dewan Pengawas</NavLinksMedia>
                  <NavLinksMedia to="/media/berita/" onClick={() => { toggleHome(); trackClick("Berita"); }}>Berita</NavLinksMedia>
                  <NavLinksMedia to="/media/pengumuman/" onClick={() => { toggleHome(); trackClick("Pengumuman"); }}>Pengumuman</NavLinksMedia>
                  <NavLinksMedia to="/media/video/" onClick={() => { toggleHome(); trackClick("Video"); }}>Video</NavLinksMedia>
                </NavDropdown>
              </NavItems>
              <NavItems>
                <NavDropdown title="Bantuan">
                  <NavLinks to="/bantuan/panduan/" onClick={() => { toggleHome(); trackClick("Panduan"); }}>Panduan</NavLinks>
                  <NavLinks to="/bantuan/download/" onClick={() => { toggleHome(); trackClick("Download"); }}>Download</NavLinks>
                  <NavLinks to="/bantuan/faq/" onClick={() => { toggleHome(); trackClick("Sering Ditanyakan"); }}>Sering Ditanyakan</NavLinks>
                  <NavLinks to="/bantuan/kontak/" onClick={() => { toggleHome(); trackClick("Hubungi Kami"); }}>Hubungi Kami</NavLinks>
                </NavDropdown>
              </NavItems>
            </NavMenu>
            <NavBtn>
              <NavBtnLink to="#" onClick={handleLogout}>Keluar</NavBtnLink>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
      <LogoutModal
        show={showModal}
        onLogin={() => history.push('/peserta')}
      />
    </>
  );
};

export default Navbar;
