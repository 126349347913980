import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { format } from 'date-fns';
import { id as localeID } from 'date-fns/locale';
import { useParams } from 'react-router-dom';
import {
  Container,
  FormWrap,
  FormContent,
  Form,
  FormInput,
  FormLabel,
  FormCardWrapper,
  FormDiv,
  FormH1,
  FormInputWrapper,
  FormInputDiv,
  FormText,
  FormButton,
  BtnLink
} from './PengkinianElements';
import {
  ErrorCard,
  MessageH1,
  MessageH2,
} from './MessageElements';
import SuccessModal from '../../Peserta/Modal/Sukses';

const PengkinianItemSection = () => {
  const { id } = useParams();
  const [data, setData] = useState({
    nama: '',
    jenis_kelamin: '',
    tempat_lahir: '',
    tanggal_lahir: '',
    no_identitas: '',
    npwp: '',
    email: '',
    alamat: '',
    rtrw: '',
    kelurahan: '',
    kecamatan: '',
    kota: '',
    provinsi: '',
    kodepos: '',
    alamat_rumah: '',
    rtrw_rumah: '',
    kelurahan_rumah: '',
    kecamatan_rumah: '',
    kota_rumah: '',
    provinsi_rumah: '',
    kodepos_rumah: '',
    no_hp: '',
    no_telp: '',
    ibu_kandung: '',
    perusahaan: '',
    pekerjaan: '',
    pemilikan: '',
    bidang_pekerjaan: '',
    alamat_kantor: '',
    kelurahan_kantor: '',
    kecamatan_kantor: '',
    kota_kantor: '',
    provinsi_kantor: '',
    kodepos_kantor: '',
    penghasilan_tetap: '',
    penghasilan_tidak_tetap: '',
    tanggal_pensiun: '',
    tanggal_pensiun_dipercepat: ''
  });


  const history = useHistory();
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [pekerjaan, setPekerjaan] = useState([]);
  const [pemilikan, setPemilikan] = useState([]);
  const [usaha, setUsaha] = useState([]);
  const [penghasilan, setPenghasilan] = useState([]);
  const [PengkinianItem, setPengkinianItem] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const cookies = new Cookies();
  const token = cookies.get('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios
          .get(process.env.REACT_APP_API_BASE_URL + `/pengkinian-data/by/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
        const formattedData = {
          ...response.data.content
        };
        setPengkinianItem(formattedData);
        setData(formattedData);
      } catch (error) {
        console.log(error.response.data.message);
      }
    };

    fetchData();
  }, [id]);

  const convertJenisKelamin = (jenis_kelamin) => {
    switch (jenis_kelamin) {
      case 'P':
        return 'Pria';
      case 'W':
        return 'Wanita';
      default:
        return jenis_kelamin;
    }
  };

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/master/pekerjaan`)
      .then(response => {
        setPekerjaan(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  }, []);

  const getNamaPekerjaan = (code) => {
      const pekerjaanItem = pekerjaan.find(item => item.code === code);
      return pekerjaanItem ? pekerjaanItem.name : '';
  };

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/master/kepemilikan`)
      .then(response => {
        setPemilikan(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  }, []);

  const getNamaPemilikan = (code) => {
      const pemilikanItem = pemilikan.find(item => item.code === code);
      return pemilikanItem ? pemilikanItem.name : '';
  };

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/master/usaha`)
      .then(response => {
        setUsaha(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  }, []);

  const getNamaUsaha = (code) => {
      const usahaItem = usaha.find(item => item.code === code);
      return usahaItem ? usahaItem.nama : '';
  };

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/master/penghasilan`)
      .then(response => {
        setPenghasilan(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  }, []);

  const getNamaPenghasilan = (code) => {
      const penghasilanItem = penghasilan.find(item => item.code === code);
      return penghasilanItem ? penghasilanItem.name : '';
  };

  const handleChange = (e) => {
    setData({
        ...data,
        [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Prepare FormData for the first POST request
      const formData = new FormData();
      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      });

      // Prepare JSON data for the second POST request
      let ppipData = { ...data };

      // Convert all string values to uppercase
      ppipData = Object.fromEntries(
        Object.entries(ppipData).map(([key, value]) =>
          [
            key,
            key === 'email' && typeof value === 'string'
              ? value.toLowerCase()
              : typeof value === 'string'
                ? value.toUpperCase()
                : value
          ]
        )
      );

      // Define the maximum lengths for each field based on the database schema
      const maxLengths = {
        nama: 100,
        tempat_lahir: 30,
        alamat: 200,
        rtrw: 7,
        kelurahan: 50,
        kecamatan: 50,
        kota: 50,
        kodepos: 30,
        no_hp: 30,
        pekerjaan: 50,
        perusahaan: 100,
        alamat_kantor: 200,
        kodepos_kantor: 30,
        kelurahan_kantor: 50,
        kecamatan_kantor: 50,
        kota_kantor: 50,
        provinsi_kantor: 50,
        alamat_rumah: 200,
        kelurahan_rumah: 50,
        kecamatan_rumah: 50,
        rtrw_rumah: 7,
        kota_rumah: 50,
        provinsi_rumah: 50,
        kodepos_rumah: 30,
        npwp: 50,
        pemilikan: 2,
        bidang_pekerjaan: 5,
        jenis_kelamin: 1,
        no_telp: 30,
        email: 50,
        no_identitas: 30,
        provinsi: 50,
        penghasilan_tetap: 1,
        penghasilan_tidak_tetap: 1,
        ibu_kandung: 100
      };

      // Trim data to fit within the maximum lengths
      Object.keys(ppipData).forEach(key => {
        if (typeof ppipData[key] === 'string' && ppipData[key].length > maxLengths[key]) {
            ppipData[key] = ppipData[key].substring(0, maxLengths[key]);
        }
      });

      const emailValue = formData.get("email") || "";

      // First POST request
      await axios
        .post(process.env.REACT_APP_API_BASE_URL + `/ppip/pengkinian-data/${PengkinianItem.no_peserta}`,
          ppipData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

      // Second POST request
      await axios
        .post(process.env.REACT_APP_API_BASE_URL + `/pengkinian-data/approve/${id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

      await axios
        .post(process.env.REACT_APP_API_BASE_URL + `/mail/approve/pengkinian-peserta/${id}`,
          {
            email: emailValue
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );

      setSuccess('Pengkinian data berhasil disimpan!');
      setShowModal(true);
      setError('');
    } catch (error) {
      let errorMessage = 'Terjadi kesalahan. Silakan coba lagi.';

      if (error.response && error.response.data) {
          if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
              // Jika `message` adalah array (misalnya daftar error validasi)
              errorMessage = error.response.data.message.map(err => err.msg).join(', ');
          } else if (typeof error.response.data.message === 'object') {
              // Jika `message` adalah objek, ubah menjadi string JSON agar tidak menyebabkan error
              errorMessage = JSON.stringify(error.response.data.message);
          }
      }

      setError(errorMessage);

      setTimeout(() => {
          setError('');
      }, 3000);
      setSuccess('');
    }
  };

  const handleDownload = (path) => {
    window.open(`/pengkinian-data/${path}`, '_blank');
  };

  return (
    <>
      <Container>
        <FormWrap>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <FormH1>Identitas Peserta</FormH1>
              <FormCardWrapper>
                {PengkinianItem && (
                <FormDiv>
                  <FormLabel htmlFor="no_peserta">Nomor Peserta</FormLabel>
                  <FormInput id="no_peserta" type="text" name="no_peserta" value={PengkinianItem.no_peserta} readOnly onChange={handleChange} />
                  <FormLabel htmlFor="nama">Nama <strong><i>(editable)</i></strong></FormLabel>
                  <FormInput id="nama" type="text" name="nama" value={data.nama} onChange={handleChange} />
                  <FormInputWrapper>
                    <FormInputDiv>
                      <FormLabel htmlFor="no_identitas">Nomor Identitas <strong><i>(editable)</i></strong></FormLabel>
                      <FormInput id="no_identitas" type="text" name="no_identitas" value={data.no_identitas} onChange={handleChange} />
                    </FormInputDiv>
                    <FormInputDiv>
                      <FormLabel htmlFor="npwp">NPWP <strong><i>(editable)</i></strong></FormLabel>
                      <FormInput id="npwp" type="text" name="npwp" value={data.npwp} onChange={handleChange} />
                    </FormInputDiv>
                  </FormInputWrapper>
                  <FormLabel htmlFor="jenis_kelamin">Jenis Kelamin</FormLabel>
                  <FormInput id="jenis_kelamin_display" type="text" value={convertJenisKelamin(PengkinianItem.jenis_kelamin)} readOnly />
                  <FormInput id="jenis_kelamin" type="hidden" name="jenis_kelamin" value={PengkinianItem.jenis_kelamin} onChange={handleChange} />
                  <FormLabel htmlFor="email">Email <strong><i>(editable)</i></strong></FormLabel>
                  <FormInput id="email" type="text" name="email" value={data.email} onChange={handleChange} />
                  <FormInputWrapper>
                    <FormInputDiv>
                      <FormLabel htmlFor="no_hp">Nomor Telepon Genggam <strong><i>(editable)</i></strong></FormLabel>
                      <FormInput id="no_hp" type="text" name="no_hp" value={data.no_hp} onChange={handleChange} />
                    </FormInputDiv>
                    <FormInputDiv>
                      <FormLabel htmlFor="no_telp">Nomor Telepon Rumah <strong><i>(editable)</i></strong></FormLabel>
                      <FormInput id="no_telp" type="text" name="no_telp" value={data.no_telp} onChange={handleChange} />
                    </FormInputDiv>
                  </FormInputWrapper>
                  <FormLabel htmlFor="ibu_kandung">Nama Gadis Ibu Kandung</FormLabel>
                  <FormInput id="ibu_kandung" type="text" name="ibu_kandung" value={PengkinianItem.ibu_kandung} readOnly onChange={handleChange} />
                  <FormInputWrapper>
                    <FormInputDiv>
                      <FormLabel htmlFor="tempat_lahir">Tempat Lahir <strong><i>(editable)</i></strong></FormLabel>
                      <FormInput id="tempat_lahir" type="text" name="tempat_lahir" value={data.tempat_lahir} onChange={handleChange} />
                    </FormInputDiv>
                    <FormInputDiv>
                      <FormLabel htmlFor="tanggal_lahir">Tanggal Lahir</FormLabel>
                      <FormInput id="tanggal_lahir_display" type="text" value={format(new Date(PengkinianItem.tanggal_lahir), 'dd MMMM yyyy', { locale: localeID })} readOnly />
                      <FormInput id="tanggal_lahir" type="hidden" name="tanggal_lahir" value={new Date(PengkinianItem.tanggal_lahir)} onChange={handleChange} />
                    </FormInputDiv>
                  </FormInputWrapper>
                  <FormInputWrapper>
                    <FormInputDiv>
                      <FormInput id="tanggal_pensiun" type="hidden" name="tanggal_pensiun" value={new Date(PengkinianItem.tanggal_pensiun)} onChange={handleChange} />
                    </FormInputDiv>
                    <FormInputDiv>
                      <FormInput id="tanggal_pensiun_dipercepat" type="hidden" name="tanggal_pensiun_dipercepat" value={new Date(PengkinianItem.tanggal_pensiun_dipercepat)} onChange={handleChange} />
                    </FormInputDiv>
                  </FormInputWrapper>
                </FormDiv>
                )}
                {PengkinianItem && (
                <FormDiv>
                  <FormLabel htmlFor="alamat">Alamat <strong><i>(editable)</i></strong></FormLabel>
                  <FormText id="alamat" name="alamat" value={data.alamat} onChange={handleChange} />
                  <FormInputWrapper>
                    <FormInputDiv>
                      <FormLabel htmlFor="rtrw">RT/RW <strong><i>(editable)</i></strong></FormLabel>
                      <FormInput id="rtrw" type="text" name="rtrw" value={data.rtrw} onChange={handleChange} />
                    </FormInputDiv>
                    <FormInputDiv>
                      <FormLabel htmlFor="kodepos">Kode Pos <strong><i>(editable)</i></strong></FormLabel>
                      <FormInput id="kodepos" type="text" name="kodepos" value={data.kodepos} onChange={handleChange} />
                    </FormInputDiv>
                  </FormInputWrapper>
                  <FormLabel htmlFor="provinsi">Provinsi <strong><i>(editable)</i></strong></FormLabel>
                  <FormInput id="provinsi" type="text" name="provinsi" value={data.provinsi} onChange={handleChange} />
                  <FormLabel htmlFor="kota">Kabupaten/Kota <strong><i>(editable)</i></strong></FormLabel>
                  <FormInput id="kota" type="text" name="kota" value={data.kota} onChange={handleChange} />
                  <FormLabel htmlFor="kecamatan">Kecamatan <strong><i>(editable)</i></strong></FormLabel>
                  <FormInput id="kecamatan" type="text" name="kecamatan" value={data.kecamatan} onChange={handleChange} />
                  <FormLabel htmlFor="kelurahan">Desa/Kelurahan <strong><i>(editable)</i></strong></FormLabel>
                  <FormInput id="kelurahan" type="text" name="kelurahan" value={data.kelurahan} onChange={handleChange} />
                  <BtnLink to="#" onClick={() => handleDownload(PengkinianItem.foto_ktp)}>Foto Kartu Identitas</BtnLink>
                  {PengkinianItem.foto_npwp && (
                    <BtnLink to="#" onClick={() => handleDownload(PengkinianItem.foto_npwp)}>Foto NPWP</BtnLink>
                  )}
                </FormDiv>
                )}
              </FormCardWrapper>

              <FormH1>Tempat Tinggal Peserta</FormH1>
              <FormCardWrapper>
                {PengkinianItem && (
                <FormDiv>
                  <FormLabel htmlFor="alamat_rumah">Alamat <strong><i>(editable)</i></strong></FormLabel>
                  <FormText id="alamat_rumah" name="alamat_rumah" value={data.alamat_rumah} onChange={handleChange} />
                  <FormInputWrapper>
                    <FormInputDiv>
                      <FormLabel htmlFor="rtrw_rumah">RT/RW <strong><i>(editable)</i></strong></FormLabel>
                      <FormInput id="rtrw_rumah" type="text" name="rtrw_rumah" value={data.rtrw_rumah} onChange={handleChange} />
                    </FormInputDiv>
                    <FormInputDiv>
                      <FormLabel htmlFor="kodepos_rumah">Kode Pos <strong><i>(editable)</i></strong></FormLabel>
                      <FormInput id="kodepos_rumah" type="text" name="kodepos_rumah" value={data.kodepos_rumah} onChange={handleChange} />
                    </FormInputDiv>
                  </FormInputWrapper>
                  <FormLabel htmlFor="provinsi_rumah">Provinsi <strong><i>(editable)</i></strong></FormLabel>
                  <FormInput id="provinsi_rumah" type="text" name="provinsi_rumah" value={data.provinsi_rumah} onChange={handleChange} />
                </FormDiv>
                )}
                {PengkinianItem && (
                <FormDiv>
                  <FormLabel htmlFor="kota_rumah">Kabupaten/Kota <strong><i>(editable)</i></strong></FormLabel>
                  <FormInput id="kota_rumah" type="text" name="kota_rumah" value={data.kota_rumah} onChange={handleChange} />
                  <FormLabel htmlFor="kecamatan_rumah">Kecamatan <strong><i>(editable)</i></strong></FormLabel>
                  <FormInput id="kecamatan_rumah" type="text" name="kecamatan_rumah" value={data.kecamatan_rumah} onChange={handleChange} />
                  <FormLabel htmlFor="kelurahan_rumah">Desa/Kelurahan <strong><i>(editable)</i></strong></FormLabel>
                  <FormInput id="kelurahan_rumah" type="text" name="kelurahan_rumah" value={data.kelurahan_rumah} onChange={handleChange} />
                </FormDiv>
                )}
              </FormCardWrapper>

              <FormH1>Data Pekerjaan</FormH1>
              <FormCardWrapper>
                {PengkinianItem && (
                <FormDiv>
                  <FormLabel htmlFor="pekerjaan">Pekerjaan</FormLabel>
                  <FormInput id="pekerjaan_display" type="text" value={getNamaPekerjaan(PengkinianItem.pekerjaan)} readOnly />
                  <FormInput id="pekerjaan" type="hidden" name="pekerjaan" value={PengkinianItem.pekerjaan} onChange={handleChange} />
                  <FormLabel htmlFor="perusahaan">Nama Perusahaan</FormLabel>
                  <FormInput id="perusahaan" type="text" name="perusahaan" value={PengkinianItem.perusahaan} readOnly onChange={handleChange} />
                  <FormLabel htmlFor="pemilikan">Kepemilikan</FormLabel>
                  <FormInput id="pemilikan_display" type="text" value={getNamaPemilikan(PengkinianItem.pemilikan)} readOnly />
                  <FormInput id="pemilikan" type="hidden" name="pemilikan" value={PengkinianItem.pemilikan} onChange={handleChange} />
                  <FormLabel htmlFor="bidang_pekerjaan">Bidang Pekerjaan</FormLabel>
                  <FormInput id="bidang_pekerjaan_display" type="text" value={getNamaUsaha(PengkinianItem.bidang_pekerjaan)} readOnly />
                  <FormInput id="bidang_pekerjaan" type="hidden" name="bidang_pekerjaan" value={PengkinianItem.bidang_pekerjaan} onChange={handleChange} />
                  <FormLabel htmlFor="penghasilan_tetap">Penghasilan Tetap (Bulan)</FormLabel>
                  <FormInput id="penghasilan_tetap_display" type="text" value={getNamaPenghasilan(PengkinianItem.penghasilan_tetap)} readOnly />
                  <FormInput id="penghasilan_tetap" type="hidden" name="penghasilan_tetap" value={PengkinianItem.penghasilan_tetap} onChange={handleChange} />
                  <FormLabel htmlFor="penghasilan_tidak_tetap">Penghasilan Tidak Tetap (Bulan)</FormLabel>
                  <FormInput id="penghasilan_tidak_tetap_display" type="text" value={getNamaPenghasilan(PengkinianItem.penghasilan_tidak_tetap)} readOnly />
                  <FormInput id="penghasilan_tidak_tetap" type="hidden" name="penghasilan_tidak_tetap" value={PengkinianItem.penghasilan_tidak_tetap} onChange={handleChange} />
                </FormDiv>
                )}
                {PengkinianItem && (
                <FormDiv>
                  <FormLabel htmlFor="alamat_kantor">Alamat <strong><i>(editable)</i></strong></FormLabel>
                  <FormText id="alamat_kantor" name="alamat_kantor" value={data.alamat_kantor} onChange={handleChange} />
                  <FormLabel htmlFor="kodepos_kantor">Kode Pos <strong><i>(editable)</i></strong></FormLabel>
                  <FormInput id="kodepos_kantor" type="text" name="kodepos_kantor" value={data.kodepos_kantor} onChange={handleChange} />
                  <FormLabel htmlFor="provinsi_kantor">Provinsi <strong><i>(editable)</i></strong></FormLabel>
                  <FormInput id="provins_kantori" type="text" name="provinsi_kantor" value={data.provinsi_kantor} onChange={handleChange} />
                  <FormLabel htmlFor="kota_kantor">Kabupaten/Kota <strong><i>(editable)</i></strong></FormLabel>
                  <FormInput id="kota_kantor" type="text" name="kota_kantor" value={data.kota_kantor} onChange={handleChange} />
                  <FormLabel htmlFor="kecamatan_kantor">Kecamatan <strong><i>(editable)</i></strong></FormLabel>
                  <FormInput id="kecamatan_kantor" type="text" name="kecamatan_kantor" value={data.kecamatan_kantor} onChange={handleChange} />
                  <FormLabel htmlFor="kelurahan_kantor">Desa/Kelurahan <strong><i>(editable)</i></strong></FormLabel>
                  <FormInput id="kelurahan_kantor" type="text" name="kelurahan_kantor" value={data.kelurahan_kantor} onChange={handleChange} />
                </FormDiv>
                )}
              </FormCardWrapper>
              <FormDiv>
                <FormButton type="submit" disabled={loading}>{loading ? 'Loading...' : 'Simpan'}</FormButton>
                {error && (
                  <ErrorCard>
                    <MessageH1><b>Gagal!</b></MessageH1>
                    <MessageH2>{error}</MessageH2>
                  </ErrorCard>
                )}
                {success && (
                  <SuccessModal show={showModal} onLogin={() => history.push('/admin/peserta/pengkinian-data/')} message={success} />
                )}
              </FormDiv>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default PengkinianItemSection;
