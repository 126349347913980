export const ManajemenHeader = {
    headline: 'Manajemen',
};

export const KantorPusatHeader = {
    headline: 'Lokasi Kantor',
};

export const PenghargaanHeader = {
    headline: 'Penghargaan',
};