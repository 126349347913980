import React, { useState } from 'react';
import {
  ProgramContainer,
  ProgramCardWrapper,
  ProgramIcon,
  ProgramCard,
  ProgramH2,
  BtnLink1,
  BtnLink2,
  BtnLink3,
  BtnLinks,
  PopupOverlay,
  PopupContent,
  PopupImage,
  PopupCloseButton,
  Heading
} from './ProgramElements';
import {
  FaTimes
} from 'react-icons/fa';

const BosowaSection = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopupOpen = () => {
    setIsPopupVisible(true);
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };

  return (
    <ProgramContainer>
      <ProgramCardWrapper>
        <ProgramIcon src={`/program/Mengapa DPLKS Muamalat.png`} />
        <ProgramCard>
          <BtnLink1 href="https://www.dplksyariahmuamalat.co.id/peserta/registrasi/"><strong>Daftar Sekarang*</strong></BtnLink1>
          <ProgramH2>
            *Masukkan kode <strong>BOSOWA-[NIK/Nomor Induk Karyawan]</strong><br/>
            pada kolom <strong>Nomor Referensi</strong> saat melakukan pendaftaran<br/>
            Contoh: BOSOWA-12345
          </ProgramH2>
          <Heading>
            <strong>Contact Person</strong>
          </Heading>
          <BtnLink2 href="https://wa.me/6281314499899" target="_blank">Selvia Dianti</BtnLink2>
          <BtnLink3 href="https://wa.me/628118500110" target="_blank">La Ode Rizal Adikrishna</BtnLink3>
          {/* <BtnLinks to="#" onClick={handlePopupOpen}>Biaya Spesial</BtnLinks> */}
        </ProgramCard>
      </ProgramCardWrapper>
      {isPopupVisible && (
        <PopupOverlay onClick={handlePopupClose}>
          <PopupContent onClick={(e) => e.stopPropagation()}>
            <PopupImage
              src={`/program/bosowa/Biaya Spesial.png`}
              alt="Biaya Spesial"
            />
          </PopupContent>
          <PopupCloseButton onClick={handlePopupClose}>
            <FaTimes size={20} />
          </PopupCloseButton>
        </PopupOverlay>
      )}
    </ProgramContainer>
  );
};

export default BosowaSection;
