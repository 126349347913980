import axios from 'axios';

export const trackClick = async (pageName) => {
    try {
        await axios.post(process.env.REACT_APP_API_BASE_URL + '/stats/page-click', {
            page_name: pageName
        })
    } catch (error) {
        console.error(error.response.data.message);
    }
};