import React from 'react';
import { Helmet } from 'react-helmet';
import BosowaSection from '../../../components/Program/Bosowa';
import Footer from '../../../components/Program/Footer';
import HeaderSection from '../../../components/Program/HeaderSection';
import Floating from '../../../components/Floating';
import Logo from '../../../components/Program/Bosowa/Logo';

const Bosowa = () => {
  return (
    <>
      <Helmet>
        <title>Program Bosowa Education | DPLK Syariah Muamalat</title>
      </Helmet>
      <HeaderSection />
      <Logo />
      <BosowaSection />
      <Footer />
      <Floating />
    </>
  );
};

export default Bosowa;