import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {
  Form,
  FormH1,
  FormInput,
  FormLabel,
  FormButton,
  FormButtonCancel,
  FormText,
} from '../FormElements';
import {
  ErrorCard,
  SuccessCard,
  MessageH1,
  MessageH2,
} from '../../../MessageElements';

const RejectForm = ({ show, handleClose, rejectData }) => {
  const [formData, setFormData] = useState({ text: '', email: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const cookies = new Cookies();
  const token = cookies.get('token');

  const handleRejectSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/mail/reject/registrasi-peserta/${rejectData.id}`,
        {
          text: formData.text,
          email: formData.email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSuccess(response.data.message);
      setTimeout(() => {
        setSuccess('');
        handleClose();
        window.location.reload();
      }, 3000);
    } catch (error) {
      setError(
        error.response?.data?.message || 'Terjadi kesalahan. Silakan coba lagi.'
      );
      setTimeout(() => setError(''), 2000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (rejectData) {
      setFormData({ text: rejectData.text, email: rejectData.email });
    }
  }, [rejectData]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <FormH1>Tolak Pendaftaran Peserta</FormH1>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormLabel htmlFor="email">Email</FormLabel>
          <FormInput id="email" type="text" value={formData.email} disabled />
          <FormLabel htmlFor="text">Deskripsi</FormLabel>
          <FormText
            id="text"
            type="text"
            value={formData.text}
            onChange={(e) =>
              setFormData({ ...formData, text: e.target.value })
            }
          />
          {error && (
            <ErrorCard>
              <MessageH1><b>Gagal!</b></MessageH1>
              <MessageH2>{error}</MessageH2>
            </ErrorCard>
          )}
          {success && (
            <SuccessCard>
              <MessageH1><b>Berhasil!</b></MessageH1>
              <MessageH2>{success}</MessageH2>
            </SuccessCard>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <FormButtonCancel type="button" onClick={handleClose}>
          Tutup
        </FormButtonCancel>
        <FormButton type="button" onClick={handleRejectSubmit} disabled={loading}>
          {loading ? 'Menyimpan...' : 'Simpan'}
        </FormButton>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectForm;
