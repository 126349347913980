import React from 'react';
import DPLK from '../../../../assets/images/DPLK.png';
import PensiunHijrah from '../../../../assets/images/pensiun-hijrah.svg';
import {
  LogoContainer,
  LogoWrap,
  SocialLogoIcon,
  LogoLinkContainer,
} from './LogoElements';

const Logo = () => {
  return (
    <LogoContainer>
      <LogoWrap>
        <LogoLinkContainer>
          <SocialLogoIcon src={DPLK} />
        </LogoLinkContainer>
        <LogoLinkContainer>
          <SocialLogoIcon src={PensiunHijrah} />
        </LogoLinkContainer>
        <LogoLinkContainer>
          <SocialLogoIcon src={`/program/toyota/TMMIN.png`} />
        </LogoLinkContainer>
      </LogoWrap>
    </LogoContainer>
  );
};

export default Logo;
