import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { differenceInYears, parseISO, isAfter } from 'date-fns';
import HasilInvestasi from '../HasilSimulasiSection';
import GrafikInvestasi from '../GrafikSimulasiSection';
import {
  Container,
  FormWrap,
  FormContent,
  Form,
  FormInput,
  FormInputCustomLeft,
  FormInputCustomRight,
  FormLabel,
  FormButton,
  FormReset,
  FormSelect,
  FormOption,
  FormCardWrapper,
  FormDiv,
  FormRupiahLeft,
  FormRupiahRight,
  FormSpanLeft,
  FormSpanRight,
  FormSpanDisabled,
  FormH2,
  FormH2Error,
  ResultDiv,
  FormH1,
  Text
} from './SimulasiElements';
import { animateScroll as scroll } from 'react-scroll';
import { trackClick } from '../../../utils/api';

const SimulasiSection = () => {
  const [isMobile, setIsMobile] = useState(false);
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const [formData, setFormData] = useState({
    tanggal_lahir: '',
    tanggal_registrasi: '',
    usia_pensiun: '',
    dana_awal: '',
    iuran: '',
    kenaikan_iuran: '',
    paket_investasi: '',
    biaya_administrasi: '2000',
    biaya_pengelolaan: ''
  });
  const [paketInvestasi, setPaketInvestasi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasilInvestasi, setHasilInvestasi] = useState(null);
  const [dataGrafik, setDataGrafik] = useState([]);
  const [errors, setErrors] = useState({});
  const hasilInvestasiRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check on component mount

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchPaketInvestasi = async () => {
      try {
        const paketAResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/parameter/INVESTASI_PAKET_A`);
        const paketBResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/parameter/INVESTASI_PAKET_B`);
        const paketCResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/parameter/INVESTASI_PAKET_C`);

        const paketA = paketAResponse.data.content.NUMERIC_VALUE;
        const paketB = paketBResponse.data.content.NUMERIC_VALUE;
        const paketC = paketCResponse.data.content.NUMERIC_VALUE;

        setPaketInvestasi([
          { id: 'A', name: 'Paket Investasi A', code: paketA },
          { id: 'B', name: 'Paket Investasi B', code: paketB },
          { id: 'C', name: 'Paket Investasi C', code: paketC },
        ]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPaketInvestasi();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.tanggal_lahir) {
      newErrors.tanggal_lahir = 'Tanggal lahir wajib diisi.';
    } else {
      const usia = differenceInYears(new Date(), parseISO(formData.tanggal_lahir));
      if (usia < 17) {
        newErrors.tanggal_lahir = 'Usia minimal adalah 17 tahun.';
      }
    }

    if (!formData.usia_pensiun || formData.usia_pensiun < 55) {
      newErrors.usia_pensiun = 'Usia pensiun harus di atas 55 tahun.';
    }

    if (!formData.iuran || formData.iuran < 0) {
      newErrors.iuran = 'Iuran per bulan tidak boleh kurang dari Rp 0.';
    }

    if (!formData.biaya_administrasi || formData.biaya_administrasi < 0) {
      newErrors.biaya_administrasi = 'Biaya administrasi per bulan tidak boleh kurang dari Rp 0.';
    }

    if (!formData.tanggal_registrasi) {
      newErrors.tanggal_registrasi = 'Tanggal registrasi wajib diisi.';
    } else if (formData.tanggal_lahir) {
      const tanggalRegistrasi = parseISO(formData.tanggal_registrasi);
      const tanggalLahir = parseISO(formData.tanggal_lahir);

      if (isAfter(tanggalLahir, tanggalRegistrasi)) {
        newErrors.tanggal_registrasi = 'Tanggal registrasi tidak boleh lebih awal dari tanggal lahir.';
      }
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
    }

    setLoading(true);

    const {
        tanggal_lahir,
        tanggal_registrasi,
        usia_pensiun,
        dana_awal,
        iuran,
        kenaikan_iuran,
        paket_investasi,
        biaya_administrasi,
    } = formData;

    const tahunLahir = parseInt(tanggal_lahir.slice(0, 4), 10);
    const bulanLahir = parseInt(tanggal_lahir.slice(5, 7), 10);
    const tahunRegistrasi = parseInt(tanggal_registrasi.slice(0, 4), 10);
    const bulanRegistrasi = parseInt(tanggal_registrasi.slice(5, 7), 10);

    let usiaMasuk = tahunRegistrasi - tahunLahir;
    if (bulanRegistrasi < bulanLahir) {
        usiaMasuk -= 1;
    }

    let akumulasiIuran = 0;
    let danaAwal = parseFloat(dana_awal);
    let iuranBulanan = parseFloat(iuran);
    let kenaikanIuran = parseFloat(kenaikan_iuran);
    let danaAkhirTahun = danaAwal + iuranBulanan;
    let akumulasi = danaAkhirTahun;
    let akumulasi_sebelum_biaya = akumulasi;
    let akumulasi_setelah_biaya = akumulasi;

    let vLoop = usia_pensiun - usiaMasuk + 1;
    let vLoop1 = 12;
    let loopData = (12 - bulanRegistrasi) + 1;

    const grafikData = [];

    for (let i = 1; i <= vLoop; i++) {
      let b = 1;
      if (i === 1) {
        b = bulanRegistrasi;
        vLoop1 = loopData;
      } else if (i === vLoop) {
        loopData = bulanLahir;
        vLoop1 = bulanLahir;
      } else {
        b = 1;
        vLoop1 = 12;
      }

      let iuranTahunan = iuranBulanan * vLoop1;
      akumulasiIuran += iuranTahunan;

      let vIuranTahunan = parseFloat(iuranTahunan);
      let vAkumulasiIuran = parseFloat(akumulasiIuran);
      let vIuranBulanan = parseFloat(iuranBulanan);

      let hasilInvestasiBulanan = 0;

      if (i === 1) {
        vLoop1 = 12;
      } else if (i === vLoop) {
        vLoop1 = bulanLahir;
      }

      for (let a = b; a <= vLoop1; a++) {
        hasilInvestasiBulanan = (paket_investasi / 100 / 12) * danaAkhirTahun;
        let akumulasi = hasilInvestasiBulanan + danaAkhirTahun;

        if (i === 1) {
          if (a !== 12) {
            danaAkhirTahun = akumulasi + iuranBulanan;
          } else {
            let biayaPengelolaan = danaAkhirTahun < 100000000 ? 1.25 : 1;
            let biayaAdm = biaya_administrasi * loopData;
            let biayaPengelolaanBulan = ((biayaPengelolaan / 100) / 12) * loopData;
            danaAkhirTahun = (akumulasi - (akumulasi * biayaPengelolaanBulan)) - biayaAdm;
            akumulasi_sebelum_biaya = akumulasi;
            akumulasi_setelah_biaya = (akumulasi - (akumulasi * biayaPengelolaanBulan)) - biayaAdm;
          }
        } else {
          if (vLoop1 === 12) {
            if (a !== 12) {
              danaAkhirTahun = akumulasi + iuranBulanan;
            } else {
              let biayaPengelolaan = danaAkhirTahun < 100000000 ? 1.25 : 1;
              danaAkhirTahun = (akumulasi - (akumulasi * (biayaPengelolaan / 100))) - biaya_administrasi * 12;
              akumulasi_sebelum_biaya = akumulasi;
              akumulasi_setelah_biaya = (akumulasi - (akumulasi * (biayaPengelolaan / 100))) - biaya_administrasi * 12;
            }
          } else {
            if (a !== vLoop1) {
              danaAkhirTahun = akumulasi + iuranBulanan;
            } else {
              let biayaPengelolaan = danaAkhirTahun < 100000000 ? 1.25 : 1;
              let biayaAdm = biaya_administrasi * vLoop1;
              let biayaPengelolaanBulan = ((biayaPengelolaan / 100) / 12) * loopData;
              danaAkhirTahun = (akumulasi - (akumulasi * biayaPengelolaanBulan)) - biayaAdm;
              akumulasi_sebelum_biaya = akumulasi;
              akumulasi_setelah_biaya = (akumulasi - (akumulasi * biayaPengelolaanBulan)) - biayaAdm;
            }
          }
        }
      }

      let vAkumulasiSebelumBiaya = parseFloat(akumulasi_sebelum_biaya);
      let vAkumulasiSetelahBiaya = parseFloat(akumulasi_setelah_biaya);

      if (kenaikanIuran >= 1) {
        let kenaikanIuranValue = (iuranBulanan * (kenaikanIuran / 100));
        iuranBulanan += kenaikanIuranValue;
        danaAkhirTahun += iuranBulanan;
      } else {
        danaAkhirTahun += iuranBulanan;
      }

      grafikData.push({
        tahun: i,
        iuranBulanan: vIuranBulanan,
        iuranTahunan: vIuranTahunan,
        akumulasiIuran: vAkumulasiIuran,
        akumulasiSebelumBiaya: vAkumulasiSebelumBiaya,
        akumulasiSetelahBiaya: vAkumulasiSetelahBiaya,
      });
    }

    const totalDana = akumulasi_setelah_biaya;
    const pajak = totalDana <= 50000000 ? 0 : (totalDana - 50000000) * 0.05;
    const totalDanaPajak = totalDana - pajak;

    setTimeout(() => {
      setHasilInvestasi({
        akumulasiIuran: (akumulasiIuran + parseFloat(dana_awal)).toFixed(2),
        hasilPengembangan: (totalDana - parseFloat(dana_awal) - akumulasiIuran).toFixed(2),
        totalDana: totalDana.toFixed(2),
        pajak: pajak.toFixed(2),
        totalDanaPajak: totalDanaPajak.toFixed(2),
      });
      setDataGrafik(grafikData);
      setLoading(false);
      scrollToHasilInvestasi();
    }, 2000);
  };

  const resetForm = () => {
    setFormData({
      tanggal_lahir: '',
      tanggal_registrasi: '',
      usia_pensiun: '',
      dana_awal: '',
      iuran: '',
      kenaikan_iuran: '',
      paket_investasi: '',
      biaya_administrasi: '2000',
      biaya_pengelolaan: ''
    });
    setHasilInvestasi(null);
    setDataGrafik([]);
    setErrors({});
  };

  const scrollToHasilInvestasi = () => {
    if (hasilInvestasiRef.current) {
      hasilInvestasiRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Container>
        <FormWrap>
          <FormContent>
            <FormH1>Simulasi Berdasarkan Iuran</FormH1>
            <Text to="/simulasi/based-on-needs/" onClick={() => { toggleHome(); trackClick("Simulasi Berdasarkan Kebutuhan"); }}>Simulasi Berdasarkan Kebutuhan →</Text>
            <Form onSubmit={handleSubmit}>
              <FormCardWrapper>
                <FormDiv>
                  <FormLabel htmlFor="tanggal_lahir">Tanggal Lahir</FormLabel>
                  <FormInput id="tanggal_lahir" type="date" name="tanggal_lahir" value={formData.tanggal_lahir} onChange={handleChange} />
                  {errors.tanggal_lahir && <FormH2Error>{errors.tanggal_lahir}</FormH2Error>}

                  <FormLabel htmlFor="tanggal_registrasi">Tanggal Registrasi</FormLabel>
                  <FormInput id="tanggal_registrasi" type="date" name="tanggal_registrasi" value={formData.tanggal_registrasi} onChange={handleChange} />
                  {errors.tanggal_registrasi && <FormH2Error>{errors.tanggal_registrasi}</FormH2Error>}

                  <FormLabel htmlFor="usia_pensiun">Rencana Usia Pensiun</FormLabel>
                  <FormInput id="usia_pensiun" type="text" name="usia_pensiun" value={formData.usia_pensiun} onChange={handleChange} />
                  {errors.usia_pensiun && <FormH2Error>{errors.usia_pensiun}</FormH2Error>}
                  <FormH2><i>Usia pensiun minimum adalah 55 tahun.</i></FormH2>

                  <FormLabel htmlFor="dana_awal">Dana Awal/Dana Pengalihan</FormLabel>
                  <FormRupiahLeft>
                    <FormSpanLeft>Rp</FormSpanLeft>
                    <FormInputCustomLeft id="dana_awal" type="text" name="dana_awal" value={formData.dana_awal} onChange={handleChange} />
                  </FormRupiahLeft>
                  <FormH2><i>Isi dengan 0 jika tidak ada Dana Awal/Dana Pengalihan.</i></FormH2>

                  <FormLabel htmlFor="iuran">Iuran Per Bulan</FormLabel>
                  <FormRupiahLeft>
                    <FormSpanLeft>Rp</FormSpanLeft>
                    <FormInputCustomLeft id="iuran" type="text" name="iuran" value={formData.iuran} onChange={handleChange} />
                  </FormRupiahLeft>
                  {errors.iuran && <FormH2Error>{errors.iuran}</FormH2Error>}

                  <FormLabel htmlFor="kenaikan_iuran">Kenaikan Iuran Per Tahun</FormLabel>
                  <FormRupiahRight>
                    <FormInputCustomRight id="kenaikan_iuran" type="text" name="kenaikan_iuran" value={formData.kenaikan_iuran} onChange={handleChange} />
                    <FormSpanRight>%</FormSpanRight>
                  </FormRupiahRight>
                  <FormH2><i>Isi dengan 0 jika tidak ingin ada kenaikan iuran.</i></FormH2>
                </FormDiv>
                <FormDiv>
                  <FormLabel htmlFor="paket_investasi">Tingkat Investasi</FormLabel>
                  <FormSelect id="paket_investasi" name="paket_investasi" value={formData.paket_investasi} onChange={handleChange}>
                    <FormOption value="" disabled>Pilih Tingkat Investasi</FormOption>
                    {paketInvestasi.map((paket) => (
                      <FormOption key={paket.id} value={paket.code}>{paket.code}%</FormOption>
                    ))}
                  </FormSelect>
                  {/* <FormH2><i>*Tiap paket memiliki tingkat indikasi hasil investasi yang berbeda dan dapat berubah sewaktu-waktu tergantung kondisi makro & mikro ekonomi.</i></FormH2> */}

                  <FormLabel htmlFor="biaya_administrasi">Biaya Administrasi (Per Bulan)</FormLabel>
                  <FormRupiahLeft>
                    <FormSpanLeft>Rp</FormSpanLeft>
                    <FormInputCustomLeft id="biaya_administrasi" name="biaya_administrasi" value={formData.biaya_administrasi} onChange={handleChange} />
                  </FormRupiahLeft>
                  {errors.biaya_administrasi && <FormH2Error>{errors.biaya_administrasi}</FormH2Error>}
                  <FormH2><i>Biaya administrasi dapat berubah sewaktu-waktu.</i></FormH2>

                  <FormLabel htmlFor="biaya_pengelolaan">Biaya Pengelolaan</FormLabel>
                  <FormInput id="biaya_pengelolaan" name="biaya_pengelolaan" value={formData.biaya_pengelolaan} placeholder="1% - 1.25% dari akumulasi dana per tahun" onChange={handleChange} disabled />
                  <FormH2><i>Biaya pengelolaan 1% jika Total Dana sebesar 100 juta rupiah atau lebih. Biaya pengelolaan 1.25% jika Total Dana kurang dari 100 juta rupiah. Biaya dapat berubah sewaktu-waktu.</i></FormH2>

                  <FormButton type="submit" disabled={loading}>{loading ? 'Loading...' : 'Hitung Simulasi'}</FormButton>
                  <FormReset type="button" onClick={resetForm}>Reset</FormReset>
                </FormDiv>
              </FormCardWrapper>
            </Form>
            <ResultDiv ref={hasilInvestasiRef}>
              {dataGrafik.length > 0 && !isMobile && <GrafikInvestasi data={dataGrafik} />}
              {hasilInvestasi && <HasilInvestasi hasilInvestasi={hasilInvestasi} data={dataGrafik} />}
            </ResultDiv>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default SimulasiSection;