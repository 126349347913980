import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  FormWrap,
  FormContent,
  Form,
  FormInput,
  FormLabel,
  FormButton,
  FormSelect,
  FormOption,
  FormCardWrapper,
  FormDiv,
  FormH3,
  FormH2,
  FormH2Error,
  FormH1,
  FormInputWrapper,
  FormInputDiv,
  FormText,
  FormRupiahLeft,
  FormSpanLeft,
  FormInputCustomLeft,
  FormAgreeLeft,
  FormH1Error,
} from './RegistrasiElements';
import {
  ErrorCard,
  MessageH1,
  MessageH2,
} from './MessageElements';
import SuccessModal from '../Modal/Sukses';

const Registrasi = () => {
  const [token, setToken] = useState(null);
  const [formData, setFormData] = useState({
    system_sender_code: '',
    nama: '',
    tanggal_lahir: '',
    tempat_lahir: '',
    no_identitas: '',
    npwp: '',
    agama: '',
    alamat: '',
    rtrw: '',
    kelurahan: '',
    kecamatan: '',
    kota: '',
    provinsi: '',
    kodepos: '',
    no_hp: '',
    email: '',
    perusahaan: '',
    paket_investasi: '',
    usia_pensiun: '',
    ibu_kandung: '',
    iuran: '',
    kode_cab_daftar: ''
  });
  const history = useHistory();
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [paketInvestasi, setPaketInvestasi] = useState([]);
  const [cabangDaftar, setCabangDaftar] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});

  const fieldRefs = {
    nama: useRef(null),
    no_identitas: useRef(null),
    tempat_lahir: useRef(null),
    tanggal_lahir: useRef(null),
    ibu_kandung: useRef(null),
    kodepos: useRef(null),
    agama: useRef(null),
    alamat: useRef(null),
    rtrw: useRef(null),
    kelurahan: useRef(null),
    kecamatan: useRef(null),
    kota: useRef(null),
    provinsi: useRef(null),
    email: useRef(null),
    no_hp: useRef(null),
    perusahaan: useRef(null),
    usia_pensiun: useRef(null),
    iuran: useRef(null),
    paket_investasi: useRef(null),
    kode_cab_daftar: useRef(null),
    channel_pendaftaran: useRef(null),
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const user = queryParams.get("user");
    const secretKey = queryParams.get("secretKey");

    if (user && secretKey) {
      authenticateUser(user, secretKey);
    }

    // Ambil data dari URL dan isi formData jika ada
    setFormData((prevData) => ({
      ...prevData,
      system_sender_code: queryParams.get("user"),
      nama: queryParams.get("nama") || prevData.nama,
      no_identitas: queryParams.get("no_identitas") || prevData.no_identitas,
      alamat: queryParams.get("alamat") || prevData.alamat,
      rtrw: queryParams.get("rtrw") || prevData.rtrw,
      kodepos: queryParams.get("kodepos") || prevData.kodepos,
      provinsi: queryParams.get("provinsi") || prevData.provinsi,
      kota: queryParams.get("kota") || prevData.kota,
      kecamatan: queryParams.get("kecamatan") || prevData.kecamatan,
      kelurahan: queryParams.get("kelurahan") || prevData.kelurahan,
      tempat_lahir: queryParams.get("tempat_lahir") || prevData.tempat_lahir,
      tanggal_lahir: queryParams.get("tanggal_lahir") || prevData.tanggal_lahir,
      agama: queryParams.get("agama") || prevData.agama,
      ibu_kandung: queryParams.get("ibu_kandung") || prevData.ibu_kandung,
      email: queryParams.get("email") || prevData.email,
      no_hp: queryParams.get("no_hp") || prevData.no_hp,
      npwp: queryParams.get("npwp") || prevData.npwp,
      perusahaan: queryParams.get("perusahaan") || prevData.perusahaan,
      usia_pensiun: queryParams.get("usia_pensiun") || prevData.usia_pensiun,
      iuran: queryParams.get("iuran") || prevData.iuran,
      kode_cab_daftar: queryParams.get("kode_cab_daftar") || prevData.kode_cab_daftar,
    }));
  }, []);

  const authenticateUser = async (user, secretKey) => {
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/client-access/auth`,
          { userClient: user, secretKey }
        );

      setToken(response.data.content.token);
    } catch (error) {
      console.log("Gagal menghubungi server autentikasi.");
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setIsCheckboxChecked(checked);
    setShowButton(checked);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/master/investasi`)
      .then(response => {
        setPaketInvestasi(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/office`)
      .then(response => {
        setCabangDaftar(response.data.content);
      })
      .catch((error) => console.error(error.response.data.message));
  }, []);

  const handleOfficeChange = (selectedOption) => {
    setFormData(prevState => ({
      ...prevState,
      kode_cab_daftar: selectedOption.value,
    }));
  };

  const options = cabangDaftar.map(office => ({
    value: office.kode_cabang,
    label: `${office.nama} - ${office.alamat}`
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: '14px',
      border: '2px solid light-dark(rgb(118, 118, 118), rgb(133, 133, 133))',
      borderRadius: '4px',
      marginBottom: '12px'
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '14px',
      backgroundColor: state.isSelected ? '#e5e5e5' : '#fff',
      color: state.isSelected ? '#212121' : '#000',
      padding: '10px'
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '14px'
    })
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.nama) {
      newErrors.nama = 'Nama harus diisi.';
    }
    if (!formData.agama) {
      newErrors.agama = 'Agama harus diisi.';
    }
    if (!formData.usia_pensiun || formData.usia_pensiun < 55) {
      newErrors.usia_pensiun = 'Usia pensiun harus diisi dan tidak kurang dari 55 tahun.';
    }
    if (!formData.paket_investasi) {
      newErrors.paket_investasi = 'Paket investasi harus diisi.';
    }
    if (!formData.tanggal_lahir || formData.tanggal_lahir === '0000-00-00') {
      newErrors.tanggal_lahir = 'Tanggal lahir harus diisi.';
    }
    if (!formData.tempat_lahir) {
      newErrors.tempat_lahir = 'Tempat lahir harus diisi.';
    }
    if (!formData.no_identitas) {
      newErrors.no_identitas = 'Nomor Kartu Identitas harus diisi.';
    }
    if (!formData.ibu_kandung) {
      newErrors.ibu_kandung = 'Nama ibu kandung harus diisi.';
    }
    if (!formData.no_hp) {
      newErrors.no_hp = 'Nomor Telepon Genggam harus diisi.';
    }
    if (!formData.alamat) {
      newErrors.alamat = 'Alamat harus diisi.';
    }
    if (!formData.rtrw) {
      newErrors.rtrw = 'RT/RW harus diisi.';
    }
    if (!formData.kelurahan) {
      newErrors.kelurahan = 'Desa/Kelurahan harus diisi.';
    }
    if (!formData.kecamatan) {
      newErrors.kecamatan = 'Kecamatan harus diisi.';
    }
    if (!formData.kota) {
      newErrors.kota = 'Kota/Kabupaten harus diisi.';
    }
    if (!formData.provinsi) {
      newErrors.provinsi = 'Provinsi harus diisi.';
    }
    if (!formData.kodepos) {
      newErrors.kodepos = 'Kode Pos harus diisi.';
    }
    if (!formData.email) {
      newErrors.email = 'Email harus diisi.';
    }
    if (!formData.perusahaan) {
      newErrors.perusahaan = 'Nama perusahaan harus diisi.';
    }
    if (!formData.kode_cab_daftar) {
      newErrors.kode_cab_daftar = 'Kode cabang daftar harus diisi.';
    }
    if (!formData.iuran) {
      newErrors.iuran = 'Jumlah iuran harus diisi.';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      const firstErrorField = Object.keys(newErrors)[0];
      if (fieldRefs[firstErrorField] && fieldRefs[firstErrorField].current) {
        fieldRefs[firstErrorField].current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        fieldRefs[firstErrorField].current.focus();
      }
    }

    setLoading(true);

    try {
      await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/client-access/registrasi`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

      setSuccess('Registrasi Peserta Baru berhasil dilakukan! Tunggu balasan kami melalui email/nomor telepon Anda untuk proses selanjutnya.');
      setShowModal(true);
      setError('');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Terjadi kesalahan. Silakan coba lagi.');
      }

      setTimeout(() => {
        setError('');
      }, 3000);
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <FormWrap>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <FormH1>Registrasi Peserta Baru</FormH1>
              <FormH1Error>(*) Data wajib diisi</FormH1Error>
              <FormCardWrapper>
                <FormDiv>
                  <FormLabel htmlFor="nama"><strong>Nama</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <FormInput id="nama" type="text" name="nama" ref={fieldRefs.nama} value={formData.nama} onChange={handleChange} />
                  {errors.nama && <FormH2Error>{errors.nama}</FormH2Error>}
                  <FormH2><i>Nama sesuai yang tertera di kartu identitas.</i></FormH2>
                  <FormLabel htmlFor="no_identitas"><strong>Nomor Identitas (NIK)</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <FormInput id="no_identitas" type="text" name="no_identitas" ref={fieldRefs.no_identitas} value={formData.no_identitas} onChange={handleChange} />
                  {errors.no_identitas && <FormH2Error>{errors.no_identitas}</FormH2Error>}
                  <FormLabel htmlFor="npwp">NPWP</FormLabel>
                  <FormInput id="npwp" type="text" name="npwp" value={formData.npwp} onChange={handleChange} />
                  <FormInputWrapper>
                    <FormInputDiv>
                      <FormLabel htmlFor="tempat_lahir"><strong>Tempat Lahir</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                      <FormInput id="tempat_lahir" type="text" name="tempat_lahir" value={formData.tempat_lahir} ref={fieldRefs.tempat_lahir} onChange={handleChange} />
                      {errors.tempat_lahir && <FormH2Error>{errors.tempat_lahir}</FormH2Error>}
                    </FormInputDiv>
                    <FormInputDiv>
                      <FormLabel htmlFor="tanggal_lahir"><strong>Tanggal Lahir</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                      <FormInput id="tanggal_lahir" type="date" name="tanggal_lahir" value={formData.tanggal_lahir} ref={fieldRefs.tanggal_lahir} onChange={handleChange} />
                      {errors.tanggal_lahir && <FormH2Error>{errors.tanggal_lahir}</FormH2Error>}
                    </FormInputDiv>
                  </FormInputWrapper>
                  <FormLabel htmlFor="agama"><strong>Agama</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <FormInput id="agama" type="text" name="agama" value={formData.agama} ref={fieldRefs.agama} onChange={handleChange} />
                  {errors.agama && <FormH2Error>{errors.agama}</FormH2Error>}
                  <FormLabel htmlFor="ibu_kandung"><strong>Nama Ibu Kandung</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <FormInput id="ibu_kandung" type="text" name="ibu_kandung" value={formData.ibu_kandung} ref={fieldRefs.ibu_kandung} onChange={handleChange} />
                  {errors.ibu_kandung && <FormH2Error>{errors.ibu_kandung}</FormH2Error>}
                  <FormLabel htmlFor="email"><strong>Email</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <FormInput id="email" type="text" name="email" value={formData.email} onChange={handleChange} ref={fieldRefs.email} />
                  {errors.email && <FormH2Error>{errors.email}</FormH2Error>}
                  <FormLabel htmlFor="no_hp"><strong>Nomor Telepon/HP</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <FormInput id="no_hp" type="text" name="no_hp" value={formData.no_hp} onChange={handleChange} ref={fieldRefs.no_hp} />
                  {errors.no_hp && <FormH2Error>{errors.no_hp}</FormH2Error>}
                </FormDiv>
                <FormDiv>
                  <FormLabel htmlFor="alamat"><strong>Alamat</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <FormText id="alamat" name="alamat" value={formData.alamat} ref={fieldRefs.alamat} onChange={handleChange} />
                  <FormH2><i>Alamat sesuai yang tertera di kartu identitas.</i></FormH2>
                  {errors.alamat && <FormH2Error>{errors.alamat}</FormH2Error>}
                  <FormInputWrapper>
                    <FormInputDiv>
                      <FormLabel htmlFor="rtrw"><strong>RT/RW</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                      <FormInput id="rtrw" type="text" name="rtrw" value={formData.rtrw} ref={fieldRefs.rtrw} onChange={handleChange} />
                      {errors.rtrw && <FormH2Error>{errors.rtrw}</FormH2Error>}
                    </FormInputDiv>
                    <FormInputDiv>
                      <FormLabel htmlFor="kodepos"><strong>Kode Pos</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                      <FormInput id="kodepos" type="text" name="kodepos" value={formData.kodepos} ref={fieldRefs.kodepos} onChange={handleChange} />
                      {errors.kodepos && <FormH2Error>{errors.kodepos}</FormH2Error>}
                    </FormInputDiv>
                  </FormInputWrapper>
                  <FormLabel htmlFor="provinsi"><strong>Provinsi</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <FormInput id="provinsi" type="text" name="provinsi" value={formData.provinsi} ref={fieldRefs.provinsi} onChange={handleChange} />
                  {errors.provinsi && <FormH2Error>{errors.provinsi}</FormH2Error>}
                  <FormLabel htmlFor="kota"><strong>Kabupaten/Kota</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <FormInput id="kota" type="text" name="kota" value={formData.kota} ref={fieldRefs.kota} onChange={handleChange} />
                  {errors.kota && <FormH2Error>{errors.kota}</FormH2Error>}
                  <FormLabel htmlFor="kecamatan"><strong>Kecamatan</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <FormInput id="kecamatan" type="text" name="kecamatan" value={formData.kecamatan} ref={fieldRefs.kecamatan} onChange={handleChange} />
                  {errors.kecamatan && <FormH2Error>{errors.kecamatan}</FormH2Error>}
                  <FormLabel htmlFor="kelurahan"><strong>Kelurahan</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <FormInput id="kelurahan" type="text" name="kelurahan" value={formData.kelurahan} ref={fieldRefs.kelurahan} onChange={handleChange} />
                  {errors.kelurahan && <FormH2Error>{errors.kelurahan}</FormH2Error>}
                  <FormLabel htmlFor="perusahaan"><strong>Nama Perusahaan</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <FormInput id="perusahaan" type="text" name="perusahaan" value={formData.perusahaan} onChange={handleChange} ref={fieldRefs.perusahaan} />
                  {errors.perusahaan && <FormH2Error>{errors.perusahaan}</FormH2Error>}
                </FormDiv>
              </FormCardWrapper>

              <FormH1>Data Kepesertaan</FormH1>
              <FormH1Error>(*) Data wajib diisi</FormH1Error>
              <FormCardWrapper>
                <FormDiv>
                  <FormLabel htmlFor="kode_cab_daftar"><strong>Kantor Cabang</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <Select
                    id="kode_cab_daftar"
                    name="kode_cab_daftar"
                    ref={fieldRefs.kode_cab_daftar}
                    options={options}
                    onChange={handleOfficeChange}
                    placeholder="Pilih cabang terdekat..."
                    styles={customStyles}
                  />
                  {errors.kode_cab_daftar && <FormH2Error>{errors.kode_cab_daftar}</FormH2Error>}
                  <FormLabel htmlFor="usia_pensiun"><strong>Usia Pensiun</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <FormInput id="usia_pensiun" type="number" name="usia_pensiun" value={formData.usia_pensiun} onChange={handleChange} ref={fieldRefs.usia_pensiun} />
                  {errors.usia_pensiun && <FormH2Error>{errors.usia_pensiun}</FormH2Error>}
                  <FormH2><i>Usia pensiun minimal 55 tahun.</i></FormH2>
                </FormDiv>
                <FormDiv>
                  <FormLabel htmlFor="paket_investasi"><strong>Paket Investasi</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <FormSelect id="paket_investasi" name="paket_investasi" value={formData.paket_investasi} onChange={handleChange} ref={fieldRefs.paket_investasi}>
                    <FormOption value='' disabled>Paket Investasi</FormOption>
                    {paketInvestasi.map((paket) => (
                      <FormOption key={paket.id} value={paket.value}>{paket.name}</FormOption>
                    ))}
                  </FormSelect>
                  {paketInvestasi.map((paket) => {
                    if (paket.value === formData.paket_investasi) {
                      return <FormH2 key={paket.id}><i>{paket.description}</i></FormH2>;
                    }
                    return null;
                  })}
                  {errors.paket_investasi && <FormH2Error>{errors.paket_investasi}</FormH2Error>}
                  <FormLabel htmlFor="iuran"><strong>Iuran</strong><span style={{ color: 'red' }}>*</span></FormLabel>
                  <FormRupiahLeft>
                    <FormSpanLeft>Rp</FormSpanLeft>
                    <FormInputCustomLeft id="iuran" type="number" name="iuran" value={formData.iuran} onChange={handleChange} ref={fieldRefs.iuran} />
                  </FormRupiahLeft>
                  {errors.iuran && <FormH2Error>{errors.iuran}</FormH2Error>}
                </FormDiv>
              </FormCardWrapper>

              <FormH1>Akad Perjanjian</FormH1>
              <FormH3>
                <ol>
                  <li>Peserta & DPLK Syariah Muamalat bersepakat melakukan <b>Akad Wakalah bil Ujrah</b></li>
                  <li>Peserta mengajukan permohonan dan menyatakan diri untuk menjadi Peserta DPLK Syariah Muamalat.</li>
                  <li>Peserta bersedia memenuhi seluruh aturan dan ketentuan yang berlaku di DPLK Syariah Muamalat sebagaimana tertuang dalam Peraturan Dana Pensiun DPLK Syariah Muamalat.</li>
                  <li>
                    Dengan <b>Akad Wakalah bil Ujrah,</b> Peserta mewakilkan DPLK Syariah Muamalat untuk:
                    <ol>
                      <li>Melakukan Kegiatan Administrasi.</li>
                      <li>Pengelolaan Dana Investasi sesuai Paket Investasi yang dipilih oleh Peserta.</li>
                    </ol>
                  </li>
                  <li>Atas akad Wakalah bil Ujrah, DPLK Syariah Muamalat berhak memperoleh imbalan/ujrah sesuai dengan ketentuan Peraturan Dana Pensiun DPLK Syariah Muamalat.</li>
                  <li>Peserta bersedia membayar biaya-biaya yang ditetapkan sesuai dengan ketentuan Peraturan Dana Pensiun DPLK Syariah Muamalat. Perjanjian ini telah disesuaikan dengan ketentuan Peraturan Perundang-Undangan yang berlaku termasuk ketentuan Peraturan Otoritas Jasa Keuangan. Demikian akad ini dibuat secara musyawarah dan mufakat yang berlaku sejak tanggal ditandatangani.</li>
                </ol>
              </FormH3>

              <FormH1>Penjelasan Program Pensiun</FormH1>
              <FormH3>
                <ol>
                  <li>DPLK Syariah Muamalat adalah suatu badan hukum Dana Pensiun berdasarkan Prinsip Syariah yang didirikan oleh PT. Bank Muamalat Indonesia Tbk</li>
                  <li>Program Pensiun DPLK Syariah Muamalat diatur berdasarkan Undang-Undang No. 4 Tahun 2023, Ketentuan Perundang-Undangan lainnya yang berlaku, dan ketentuan Peraturan Otoritas Jasa Keuangan yang berlaku, termasuk Program Pensiun DPLK Syariah Muamalat bersifat Iuran Pasti (IP).</li>
                  <li>Peserta DPLK Syariah Muamalat yaitu perorangan baik karyawan maupun pekerja mandiri yang memenuhi syarat kepesertaan sesuai dengan Peraturan Dana Pensiun DPLK Syariah Muamalat.</li>
                  <li>Iuran adalah sejumlah uang tertentu yang disetorkan oleh Peserta dan atau Pemberi Kerja kepada DPLK Syariah Muamalat yang dibukukan ke rekening masing-masing Peserta.</li>
                  <li>Usia Pensiun Normal yang dapat dipilih oleh Peserta sekurang-kurangnya 55 (lima puluh lima) tahun. Dalam hal peserta diikutkan oleh Pemberi Kerja, maka pilihan Usia Pensiun Normal yang berlaku pada Pemberi Kerja.</li>
                  <li>Usia Pensiun Dipercepat adalah sekurang-kurangnya 5 (lima) tahun sebelum Usia Pensiun Nomal.</li>
                  <li>Hak-hak Peserta adalah menentukan usia pensiun, menentukan pilihan dan perubahan jenis investasi, melakukan penarikan sejumlah dana tertentu, meminta informasi mengenai dana peserta yang dimiliki, menunjuk atau mengganti Pihak Yang Berhak (Janda/Duda/Anak atau Pihak Yang Ditunjuk), memilih bentuk anuitas dan memilih Perusahaan Asuransi Jiwa dalam rangka pembayaran manfaat pensiun, mengalihkan kepesertaannya ke Dana Pensiun lain, memperoleh pembayaran manfaat pensiun Secara Sekaligus, dan meminta bukti potong pajak atas penarikan dana oleh Peserta.</li>
                  <li>Kewajiban Peserta adalah menyetor iuran, membayar biaya- biaya yang telah ditetapkan, memberikan keterangan yang lengkap dan benar sesuai yang dibutuhkan oleh DPLK Syariah Muamalat, menaati segala ketentuan yang telah ditetapkan dalam Peraturan DPLK Syariah Muamalat, dan melaporkan ke DPLK Syariah Muamalat setiap terjadi perubahan susunan keluarga, dan atau perubahan alamat dengan mengisi Formulir yang disediakan.</li>
                  <li>Manfaat Pensiun adalah akumulasi iuran dan hasil pengembangan serta dana pengalihan dari Dana Pensiun lain (jika ada) selama masa kepesertaan, yang akan diterima oleh Peserta Secara Sekaligus dan atau Secara Berkala sesuai dengan Peraturan Otoritas Jasa Keuangan (OJK) di bidang Dana Pensiun.</li>
                  <li>Manfaat Pensiun Peserta terdiri dari Manfaat Pensiun Normal, Manfaat Pensiun Dipercepat, Hak atas Pensiun Ditunda, Manfaat Pensiun Disabilitas, dan Manfaat Pensiun Peserta Berhenti Bekerja dengan saldo dana kurang atau sama dengan Rp 100.000.000,00,- (seratus juta rupiah).</li>
                  <li>Paket Investasi Syariah adalah sekumpulan jenis Investasi Syariah berdasarkan Prinsip Syariah yang ditawarkan oleh DPLK Syariah Muamalat. Peserta dapat memilih Paket Investasi yang disediakan terdiri dari Paket A, Paket B, dan atau Paket C. Peserta dapat melakukan perubahan Paket Investasi sesuai Peraturan Dana Pensiun DPLK Syariah Muamalat. Segala resiko dan biaya yang timbul dari pilihan dan perubahan pilihan jenis investasi menjadi tanggung jawab Peserta.</li>
                  <li>Selama Dana Peserta dikelola oleh DPLK Syariah Muamalat tidak dikenakan pajak. Pajak diberlakukan ketika Peserta menarik sebagian atau keseluruhan Iuran Pensiun, dan atau pada saat Pembayaran Manfaat Pensiun.</li>
                  <li>Jika Peserta meninggal dunia, maka pihak yang akan memperoleh Manfaat Pensiun adalah Ahli Waris/Pihak Yang Ditunjuk.</li>
                </ol>
              </FormH3>

              <FormH1>Pernyataan Peserta</FormH1>
              <FormH3>
                Dengan menekan tombol <b>Kirim</b>, maka saya menyatakan:
                <ol>
                  <li>Data pribadi saya yang saya berikan dalam formulir ini adalah benar.</li>
                  <li>DPLK Syariah Muamalat dapat melakukan pemeriksaan terhadap kebenaran data yang saya berikan.</li>
                  <li>DPLK Syariah Muamalat telah memberikan penjelasan yang cukup mengenai karakteristik produk DPLK Syariah Muamalat, dan saya telah memahami konsekwensi atas kepesertaan saya di DPLK Syariah Muamalat termasuk manfaat, resiko, dan biaya-biaya yang melekat pada produk tersebut serta kinerja investasi DPLK Syariah Muamalat pada masa lalu bukan merupakan kinerja di masa yang akan datang.</li>
                  <li>Saya setuju DPLK Syariah Muamalat menggunakan dan memberikan data dan atau informasi pribadi saya kepada pihak yang lain yang bekerja sama dengan DPLK Syariah Muamalat.</li>
                  <li>DPLK Syariah Muamalat berhak untuk menolak transaksi, membatalkan transaksi, dan/atau menutup hubungan usaha dengan saya dalam hal saya tidak memenuhi ketentuan Pasal 49 Peraturan Jasa Otoritas Keuangan Nomor 8 Tahun 2023 serta ketentuan turunan dan/atau perubahannya.</li>
                  <li>Saya setuju memberikan kuasa kepada DPLK Syariah Muamalat untuk memindahkan paket investasi apabila terjadi penurunan investasi dengan presentasi tertentu.</li>
                  <li>Saya setuju memberikan kuasa kepada DPLK Syariah Muamalat untuk mengelola aset sesuai dengan usia kelompok peserta ketika mencapai usia paling lama 5 (lima) tahun dan paling cepat 2 (dua) tahun sebelum usia pensiun normal, untuk ditempatkan pada instrumen yang memiliki risiko rendah sesuai dengan ketentuan yang berlaku.</li>
                </ol>
              </FormH3>
              <FormDiv>
                <FormAgreeLeft>
                  <FormInput type='checkbox' id='checkbox_agree' name='checkbox_agree' checked={isCheckboxChecked} onChange={handleCheckboxChange} />
                  <FormLabel htmlFor='checkbox_agree'>Saya telah membaca dan menyetujui ketentuan yang berlaku.</FormLabel>
                </FormAgreeLeft>
                {error && (
                  <ErrorCard>
                    <MessageH1><b>Gagal!</b></MessageH1>
                    <MessageH2>{error}</MessageH2>
                  </ErrorCard>
                )}
                {success && (
                  <SuccessModal show={showModal} message={success} />
                )}
                {showButton && (
                  <FormButton type="submit" disabled={loading}>{loading ? 'Loading...' : 'Kirim'}</FormButton>
                )}
              </FormDiv>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default Registrasi;
