import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { format, isValid } from 'date-fns';
import { id as localeID } from 'date-fns/locale';
import { Table } from 'react-bootstrap';
import {
  PaketInvestasiContainer,
  PaketInvestasiWrapper,
  EditLink,
  DeleteLink
} from './PaketInvestasiElements';
import '../../../style.css';
import {
  FaCheck, FaWindowClose
} from 'react-icons/fa';
import RejectForm from './Form/Rejected';
import SuccessModal from '../Modal/Success';

const PerubahanPaketInvestasi = ({ userData }) => {
  const [state, setState] = useState({
    data: [],
    isMobile: window.innerWidth <= 820, // Menentukan apakah tampilan saat ini adalah mobile
    showRejectForm: false,
    rejectData: {
      id: null,
      email: ''
    },
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const cookies = new Cookies();
  const token = cookies.get('token');

  useEffect(() => {
    fetchData();
    window.addEventListener('resize', handleWindowSizeChange); // Menambahkan event listener untuk menghandle perubahan ukuran layar
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange); // Membersihkan event listener saat komponen dilepas
    };
  }, []);

  const handleWindowSizeChange = () => {
    setState((prev) => ({
      ...prev,
      isMobile: window.innerWidth <= 820 // Mengupdate status isMobile saat terjadi perubahan ukuran layar
    }));
  };

  const fetchData = () => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + `/paket-investasi`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const modifiedData = response.data.content.map((item) => {
          let statusText;
          if (item.status === false) {
            statusText = 'Ditolak';
          } else if (item.status === true) {
            statusText = 'Disetujui';
          } else {
            statusText = 'Belum Diproses';
          }
          return {
            ...item,
            status: statusText,
            createdAt: isValid(new Date(item.createdAt)) ? format(new Date(item.createdAt), 'dd MMMM yyyy', { locale: localeID }) : '',
          };
        });

        setState((prev) => ({
          ...prev,
          data: modifiedData
        }));
      })
      .catch((error) => {
        console.error(error.response.data.message);
      });
  };

  const handleShowRejectForm = (id) => {
    const dataToReject = state.data.find((item) => item.id === id);
    if (dataToReject) {
      setState((prev) => ({
        ...prev,
        showRejectForm: true,
        rejectData: dataToReject,
      }));
    } else {
      console.log('Data tidak ditemukan!');
    }
  };

  const handleCloseRejectForm = () => {
    setState((prev) => ({
      ...prev,
      showRejectForm: false
    }));
  };

  const handleRejectInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      rejectData: {
        ...prev.rejectData,
        [name]: value,
      },
    }));
  };

  const handleRejectSubmit = () => {
    console.log('Data rejected:', state.rejectData);
    handleCloseRejectForm();
  };

  const handleApprove = async (noPeserta, id, email, paketInvestasi) => {
    try {
      // Jalankan API pertama
      await axios.post(
        process.env.REACT_APP_API_BASE_URL + `/ppip/paket-investasi/${noPeserta}`,
        { paket_investasi: paketInvestasi },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Jalankan API kedua
      await axios.post(
        process.env.REACT_APP_API_BASE_URL + `/paket-investasi/approve/${id}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      await axios
        .post(process.env.REACT_APP_API_BASE_URL + `/mail/approve/paket-investasi/${id}`,
          { email },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
      );

      // Tampilkan modal sukses setelah berhasil
      setShowSuccessModal(true);

      // Refresh data setelah approve
      fetchData();
    } catch (error) {
      let errorMessage = 'Terjadi kesalahan. Silakan coba lagi.';

      if (error.response && error.response.data) {
          if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
              // Jika `message` adalah array (misalnya daftar error validasi)
              errorMessage = error.response.data.message.map(err => err.msg).join(', ');
          } else if (typeof error.response.data.message === 'object') {
              // Jika `message` adalah objek, ubah menjadi string JSON agar tidak menyebabkan error
              errorMessage = JSON.stringify(error.response.data.message);
          }
      }
      console.error(errorMessage);
    }
  };

  return (
    <PaketInvestasiContainer id="paket-investasi">
      <RejectForm
        show={state.showRejectForm}
        handleClose={handleCloseRejectForm}
        rejectData={state.rejectData}
        handleRejectInputChange={handleRejectInputChange}
        handleRejectSubmit={handleRejectSubmit}
        userData={userData}
      />
      {!state.isMobile && ( // Hanya menampilkan tabel dan pagination jika bukan tampilan mobile
        <>
          <PaketInvestasiWrapper>
            <Table striped bordered hover className="paket-investasi">
              <thead>
                <tr>
                  <th>Nomor Peserta</th>
                  <th>Nama</th>
                  <th>Paket Investasi Awal</th>
                  <th>Paket Investasi Baru</th>
                  <th>Status</th>
                  <th>Catatan</th>
                  <th>Tanggal Diajukan</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {state.data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.no_peserta}</td>
                    <td>{item.nama}</td>
                    <td>{item.kode_paket_lama}</td>
                    <td>{item.kode_paket_baru}</td>
                    <td>{item.status}</td>
                    <td>{item.catatan}</td>
                    <td>{item.createdAt}</td>
                    <td>
                      {item.status === 'Belum Diproses' && (
                        <>
                          <EditLink to="#" title="Approve" onClick={() => handleApprove(item.no_peserta, item.id, item.email, item.kode_paket_baru)}>
                            <FaCheck />
                          </EditLink>
                          <DeleteLink to="#" onClick={() => handleShowRejectForm(item.id)} title="Reject">
                            <FaWindowClose />
                          </DeleteLink>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </PaketInvestasiWrapper>
        </>
      )}
      {state.isMobile && ( // Hanya menampilkan nama dan status jika tampilan mobile
        <>
          <PaketInvestasiWrapper>
            <Table striped bordered hover className="paket-investasi">
              <thead>
                <tr>
                  <th>Nama</th>
                  <th>Paket Investasi Baru</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {state.data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.nama}</td>
                    <td>{item.kode_paket_baru}</td>
                    <td>
                      {item.status === 'Belum Diproses' && (
                        <>
                          <EditLink to="#" title="Approve" onClick={() => handleApprove(item.no_peserta, item.id, item.email, item.kode_paket_baru)}>
                            <FaCheck />
                          </EditLink>
                          <DeleteLink to="#" onClick={() => handleShowRejectForm(item.id)} title="Reject">
                            <FaWindowClose />
                          </DeleteLink>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </PaketInvestasiWrapper>
        </>
      )}
      <SuccessModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        message="Paket investasi berhasil disetujui!"
      />
    </PaketInvestasiContainer>
  );
};

export default PerubahanPaketInvestasi;
