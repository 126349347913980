import React, { useState } from 'react';
import {
  ProgramContainer,
  ProgramCardWrapper,
  ProgramIcon,
  ProgramCard,
  ProgramH2,
  BtnLink1,
  BtnLink2,
  BtnLink3,
  BtnLinks,
  PopupOverlay,
  PopupContent,
  PopupImage,
  PopupCloseButton
} from './ProgramElements';
import {
  FaTimes
} from 'react-icons/fa';

const ToyotaSection = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopupOpen = () => {
    setIsPopupVisible(true);
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };

  return (
    <ProgramContainer>
      <ProgramCardWrapper>
        <ProgramIcon src={`/program/Mengapa DPLKS Muamalat.png`} />
        <ProgramCard>
          <BtnLink1 href="https://www.dplksyariahmuamalat.co.id/peserta/registrasi/"><strong>Daftar Sekarang*</strong></BtnLink1>
          <ProgramH2>
            *Masukkan kode <strong>TMMIN-[NIK/Nomor Induk Karyawan]</strong><br/>
            pada kolom <strong>Nomor Referensi</strong> saat melakukan pendaftaran<br/>
            Contoh: TMMIN-12345
          </ProgramH2>
          {/* <BtnLink2 href="https://linktr.ee/muamalatXtelkomsel" target="_blank">Panduan Informasi</BtnLink2>
          <BtnLinks to="#" onClick={handlePopupOpen}>Biaya Spesial</BtnLinks>
          <BtnLink3 href="https://linktr.ee/PICTelkomsel" target="_blank">Contact Person</BtnLink3> */}
        </ProgramCard>
      </ProgramCardWrapper>
      {isPopupVisible && (
        <PopupOverlay onClick={handlePopupClose}>
          <PopupContent onClick={(e) => e.stopPropagation()}>
            <PopupImage
              src={`/telkomsel/Biaya Spesial.png`}
              alt="Biaya Spesial"
            />
          </PopupContent>
          <PopupCloseButton onClick={handlePopupClose}>
            <FaTimes size={20} />
          </PopupCloseButton>
        </PopupOverlay>
      )}
    </ProgramContainer>
  );
};

export default ToyotaSection;
