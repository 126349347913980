export const PanduanHeader = {
    headline: 'Panduan',
};

export const FormulirHeader = {
    headline: 'Download',
};

export const FAQHeader = {
    headline: 'Sering Ditanyakan',
};

export const KontakHeader = {
    headline: 'Hubungi Kami',
};