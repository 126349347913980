export const IndividuHeader = {
    headline: 'Pensiun Hijrah',
};

export const PerusahaanHeader = {
    headline: 'Pensiun Hijrah Pasca Kerja',
};

export const EksekutifHeader = {
    headline: 'Pensiun Hijrah Eksekutif',
};

export const ZakatHeader = {
    headline: 'Zakat Dana Pensiun',
};

export const PensiunSyariahHeader = {
    headline: 'Kenapa Harus Syariah?',
};