export const dataDashboard = {
  id: 'dashboard',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  headline: 'Selamat Datang!',
  imgStart: false,
  img: '/images/image-7.svg',
  alt: 'dashboard',
  darkText: true
};