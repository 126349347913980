import React, { useState, useEffect } from 'react';
import Logo from '../../assets/images/LogoPensiunHijrahWidth.png';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import {
  MobileIcons,
  Nav,
  NavbarContainer,
  NavBtn,
  NavBtnLink,
  NavItems,
  Navlogo,
  NavMenu,
  NavIcon,
  NavLinks,
  NavLinkS,
  SubMenu,
  SubMenuItem,
  NavLinksMedia
} from './NavbarElements';
import { NavDropdown } from 'react-bootstrap';
import { animateScroll as scroll } from 'react-scroll';
import { trackClick } from '../../utils/api';

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [showIndividuSubMenu, setShowIndividuSubMenu] = useState(false);
  const [showNonIndividuSubMenu, setShowNonIndividuSubMenu] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
    return () => {
      window.removeEventListener('scroll', changeNav);
    };
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <Navlogo to="/" onClick={() => { toggleHome(); trackClick("Home"); }}>
              <NavIcon src={Logo} />
            </Navlogo>
            <MobileIcons onClick={toggle}>
              <FaBars />
            </MobileIcons>
            <NavMenu>
              <NavItems>
                <NavDropdown title="Tentang Kami">
                  <NavLinks to="/about/dplk/" onClick={() => { toggleHome(); trackClick("Tentang DPLK"); }}>Tentang DPLK</NavLinks>
                  <NavLinks to="/about/manajemen/" onClick={() => { toggleHome(); trackClick("Manajemen"); }}>Manajemen</NavLinks>
                  <NavLinks to="/about/lokasi/" onClick={() => { toggleHome(); trackClick("Lokasi Kantor"); }}>Lokasi Kantor</NavLinks>
                  <NavLinks to="/about/penghargaan/" onClick={() => { toggleHome(); trackClick("Penghargaan"); }}>Penghargaan</NavLinks>
                  <NavLinkS href="https://www.bankmuamalat.co.id/" target="_blank" onClick={() => trackClick("Bank Muamalat")}>Bank Muamalat</NavLinkS>
                </NavDropdown>
              </NavItems>
              <NavItems>
                <NavDropdown title="Produk">
                  <NavLinks
                    to="#"
                    onMouseEnter={() => setShowIndividuSubMenu(true)}
                    onMouseLeave={() => setShowIndividuSubMenu(false)}
                  >
                    Individu
                    {showIndividuSubMenu && (
                      <SubMenu>
                        <SubMenuItem to="/product/pensiun-hijrah/" onClick={() => { toggleHome(); trackClick("Pensiun Hijrah"); }}>Pensiun Hijrah</SubMenuItem>
                      </SubMenu>
                    )}
                  </NavLinks>
                  <NavLinks
                    to="#"
                    onMouseEnter={() => setShowNonIndividuSubMenu(true)}
                    onMouseLeave={() => setShowNonIndividuSubMenu(false)}
                  >
                    Non-Individu
                    {showNonIndividuSubMenu && (
                      <SubMenu>
                        <SubMenuItem to="/product/pensiun-hijrah/" onClick={() => { toggleHome(); trackClick("Pensiun Hijrah"); }}>Pensiun Hijrah</SubMenuItem>
                        <SubMenuItem to="/product/pensiun-hijrah-pasca-kerja/" onClick={() => { toggleHome(); trackClick("Pensiun Hijrah Pasca Kerja"); }}>Pensiun Hijrah Pasca Kerja</SubMenuItem>
                        <SubMenuItem to="/product/pensiun-hijrah-eksekutif/" onClick={() => { toggleHome(); trackClick("Pensiun Hijrah Eksekutif"); }}>Pensiun Hijrah Eksekutif</SubMenuItem>
                      </SubMenu>
                    )}
                  </NavLinks>
                  <NavLinks to="/product/zakat-dana-pensiun/" onClick={() => { toggleHome(); trackClick("Zakat Dana Pensiun"); }}>Zakat Dana Pensiun</NavLinks>
                </NavDropdown>
              </NavItems>
              <NavItems>
                <NavDropdown title="Simulasi">
                  <NavLinks to="/simulasi/" onClick={() => { toggleHome(); trackClick("Simulasi Berdasarkan Iuran"); }}>Berdasarkan Iuran</NavLinks>
                  <NavLinks to="/simulasi/based-on-needs/" onClick={() => { toggleHome(); trackClick("Simulasi Berdasarkan Kebutuhan"); }}>Berdasarkan Kebutuhan</NavLinks>
                </NavDropdown>
              </NavItems>
              <NavItems>
                <NavDropdown title="Pusat Media">
                  <NavLinksMedia to="/media/investasi/" onClick={() => { toggleHome(); trackClick("Ringkasan Investasi"); }}>Ringkasan Investasi (Fund Fact Sheet)</NavLinksMedia>
                  <NavLinksMedia to="/media/keuangan/" onClick={() => { toggleHome(); trackClick("Laporan Keuangan"); }}>Laporan Keuangan</NavLinksMedia>
                  <NavLinksMedia to="/media/dewan-pengawas/" onClick={() => { toggleHome(); trackClick("Laporan Dewan Pengawas"); }}>Laporan Dewan Pengawas</NavLinksMedia>
                  <NavLinksMedia to="/media/berita/" onClick={() => { toggleHome(); trackClick("Berita"); }}>Berita</NavLinksMedia>
                  <NavLinksMedia to="/media/pengumuman/" onClick={() => { toggleHome(); trackClick("Pengumuman"); }}>Pengumuman</NavLinksMedia>
                  <NavLinksMedia to="/media/video/" onClick={() => { toggleHome(); trackClick("Video"); }}>Video</NavLinksMedia>
                </NavDropdown>
              </NavItems>
              <NavItems>
                <NavDropdown title="Bantuan">
                  <NavLinks to="/bantuan/panduan/" onClick={() => { toggleHome(); trackClick("Panduan"); }}>Panduan</NavLinks>
                  <NavLinks to="/bantuan/download/" onClick={() => { toggleHome(); trackClick("Download"); }}>Download</NavLinks>
                  <NavLinks to="/bantuan/faq/" onClick={() => { toggleHome(); trackClick("Sering Ditanyakan"); }}>Sering Ditanyakan</NavLinks>
                  <NavLinks to="/bantuan/kontak/" onClick={() => { toggleHome(); trackClick("Hubungi Kami"); }}>Hubungi Kami</NavLinks>
                </NavDropdown>
              </NavItems>
            </NavMenu>
            <NavBtn>
              <NavBtnLink to="/peserta/" onClick={() => { toggleHome(); trackClick("Login Peserta"); }}>Cek Saldo</NavBtnLink>
              <NavBtnLink to="/peserta/registrasi/" onClick={() => { toggleHome(); trackClick("Registrasi"); }}>Registrasi</NavBtnLink>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;