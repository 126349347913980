export const DashboardHeader = {
    headline: 'MyDPLK',
};
export const ProfilHeader = {
    headline: 'Profil',
};
export const SaldoHeader = {
    headline: 'Cek Saldo',
};
export const RiwayatHeader = {
    headline: 'Riwayat Transaksi',
};
export const ResetHeader = {
    headline: 'Ubah Password',
};
export const LupaPasswordHeader = {
    headline: 'Lupa Password',
};
export const RegistrasiHeader = {
    headline: 'Registrasi Perusahaan',
};
export const DaftarHeader = {
    headline: 'Daftar Peserta',
};