import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Registrasi from '../../../components/Microsite/Registrasi';

const RegistrasiMicrosite = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Helmet>
        <title>Registrasi Peserta Baru | DPLK Syariah Muamalat</title>
      </Helmet>
      <Registrasi />
    </>
  );
};

export default RegistrasiMicrosite;