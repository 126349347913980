export const NewsHeader = {
    headline: 'Berita dan Artikel',
};

export const AnnouncementHeader = {
    headline: 'Pengumuman',
};

export const VideoHeader = {
    headline: 'Video',
};

export const KeuanganHeader = {
    headline: 'Laporan Keuangan',
};

export const DPHeader = {
    headline: 'Laporan Dewan Pengawas',
};

export const FunFactHeader = {
    headline: 'Ringkasan Investasi',
};