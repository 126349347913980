import React from 'react';
import { Helmet } from 'react-helmet';
import ToyotaSection from '../../../components/Program/Toyota';
import Footer from '../../../components/Program/Footer';
import HeaderSection from '../../../components/Program/HeaderSection';
import Floating from '../../../components/Floating';
import Logo from '../../../components/Program/Toyota/Logo';

const Toyota = () => {
  return (
    <>
      <Helmet>
        <title>Program Toyota | DPLK Syariah Muamalat</title>
      </Helmet>
      <HeaderSection />
      <Logo />
      <ToyotaSection />
      <Footer />
      <Floating />
    </>
  );
};

export default Toyota;